.v2
  font-family: 'proxima-nova-soft'
  background-color: #fbfbfb
  display: flex
  flex-direction: column
  .align-items-center
    align-items: center
  .card
    border: 1px
    border-radius: 8px
    .card-header
      background-color: initial
      border-bottom: initial
  &.menus
    overflow: hidden
  .badge
    border-radius: 128px
  .layout-color
    background-color: $v2-background-color
  .sidebar-v2
    flex-basis: 300px
    background-color: #F4F5F5 !important
    color: white !important
    padding: 20px 20px 20px 30px
    display: flex
    flex-direction: column
    .icon-button
      display: flex
      align-items: center
    .icon-container
      margin-right: 25px
      svg
        height: 30px
        width: 30px
    .group-icons-end
      flex-direction: column
      flex: 1
      justify-content: flex-end

  .sidebar-v2 a
      padding: 8px 25px
      text-align: center
      text-decoration: none
      font-size: 18px
      color: #515154
      display: block
      font-weight: 600

  .sidebar-v2 a.active
    background-color: #7D9E1D14
    color: black
    border-radius: 8px
    svg
      path
        fill: $v2-primary-color


  .sidebar-v2 a:hover
    background-color: #7D9E1D14
    color: black
    border-radius: 8px
    svg
      path
        fill: $v2-primary-color
    svg.logout-icon
      path
        stroke: $v2-primary-color
        fill: none
  .navigation-bar
    border: 0px 0px 1px 0px
    padding: 8px 24px 8px 24px
    background-color: #FFFFFF
    .btn-naked
      background-color: white
      padding-top: 8px !important
    .dropdown-menu.dropdown-menu-right
      padding: 20px
  .vertical-line
    border: none
    border-left: 2px solid #333
    height: 100px
    transform: rotate(90deg)
  .action-required
    border-radius: 8px
    @media (min-width: 1200px)
      padding: 8px 16px 16px 0px
      margin-top: 20px
    .d-flex.align-items-center
      justify-content: space-between
    @media (max-width: 1200px)
      margin-top: 0px
    .action-required-text
      font-size: 20px
      font-weight: 600
    .action-required-value
      margin-left: 10px
      padding: 0px 8px 0px 8px
      border: 1px
      color: white
      border-radius: 20px
      background-color: #EE8434
      height: 26px
      width: max-content
      display: inline-flex
      justify-content: center
      align-items: center
    .action-required-more
      margin-right: 4px
  .action-required-container
    width: 100%
    overflow: scroll hidden
    height: auto
    white-space: nowrap
    -ms-overflow-style: none
    scrollbar-width: none
    height: 180px
  .action-required-container::-webkit-scrollbar
    display: none
  .overview
    padding-top: 16px
    padding-bottom: 16px
    border-top: 1px #EAEAEB solid
    border-bottom: 1px #EAEAEB solid
    @media (max-width: 1200px)

    .overview-header
      align-items: center
      @media (max-width: 1200px)
        padding-left: 20px
    .overview-title
      font-weight: 600
      font-size: 20px
      margin-right: 1rem
    .overview-welcome
      font-weight: 700
      font-size: 20px
    .overview-percent
      font-size: 14px
      font-weight: 600
    .overview-percent.increase
      color: #7D9E1D
    .overview-percent.increase:before
      display: inline-block
      width: 0
      height: 0
      margin-right: 0.3em
      vertical-align: middle
      content: ""
      border-top: 0.3em solid
      border-right: 0.3em solid transparent
      border-left: 0.3em solid transparent
    .overview-percent.decrease
      color: #C3423F
    .overview-percent.decrease:before
      display: inline-block
      width: 0
      height: 0
      margin-right: 0.3em
      vertical-align: middle
      content: ""
      border-bottom: 0.3em solid
      border-right: 0.3em solid transparent
      border-left: 0.3em solid transparent
    .performance-date-picker
      @media (max-width: 1200px)
        display: none !important
    .overview-cards
      justify-content: space-between
      @media (max-width: 1200px)
        flex-direction: column
        padding: 0px 20px 0px 20px
      .overview-card
        flex-direction: column
        justify-content: center
        background-color: #F4F5F5
        border-radius: 8px
        padding: 20px
        width: 100%
        height: 100%
        transition: height 300ms
        .overview-text
          font-family: 'proxima-nova-soft'
          font-weight: 400
          font-size: 16px
          margin-bottom: 12px
          display: flex
          align-items: center
          justify-content: space-between
          word-wrap: break-word
          .mobile-overview-text
            margin-left: 5px
        .overview-line
          border-bottom: 1px #EAEAEB solid
        .overview-active-orders
          padding-top: 32px
          padding-bottom: 10px
        .overview-value
          font-weight: 700
          font-size: 20px
        .group-value-percent
          justify-content: space-between
      .overview-card:not(:last-child)
        @media (min-width: 1200px)
          margin-right: 10px
      .overview-card:last-child
        @media (max-width: 1200px)
          margin-top: 10px
  .mobile-header
    position: -webkit-sticky
    position: fixed
    top: 0
    background-color: #fff
    z-index: 2
    padding: 20px 20px 10px 20px
    width: 100%
    box-shadow: 0px 8px 16px rgba(20,20,21,.08)
    @media (min-width: 1200px)
      display: none

  .content
    .container
      width: 100% !important
      max-width: initial

@media (min-width: 992px)
  .v2 .container
    max-width: 398px
@media (min-width: 1200px)
  .v2 .container
    max-width: 398px
  footer, header, .outlet-selector
    display: none !important
  .v2
    background-color: #FBFBFB
@media (min-width: 1400px)
  .v2 .container
    max-width: 398px
  footer, header, .outlet-selector
    display: none !important
  .v2
    background-color: #FBFBFB
@media (max-width: 1200px)
  .sidebar-v2, .navigation-bar
    display: none !important
body
  min-height: 100vh
  margin: 0
  @media (min-width: 1200px)
    height: 100vh !important

header
  min-height: 130px
  background: rgba(242, 245, 232, 1)
  padding: 20px
  z-index: 999

.outlet-selector
  z-index: 999
  margin-top: -70px
  padding: 20px
  z-index:999
  top:0
  .outlet-selector-container
    display: flex
    align-items: center
    justify-content: space-between
    .text-group
      display: flex
      flex-direction: column
      margin-left: 10px
    .text-outlets
      font-size: 18px
    .text-vendor
      color: #95989D
      font-size: 14px
.header-desktop
  .caterspot-logo
    margin-left: 80px
  .outlet-selector-container
    border: 1px solid #F2F5E8
    border-radius: 8px
    margin-left: 100px
    width: fit-content
    padding: 5px 10px 0px 10px
  .header-end-section
    flex-direction: row
    flex: 1
    justify-content: flex-end
    align-items: center
  .header-avatar
    border-radius: 4px
    padding: 10px 16px 10px 16px
    background-color: #BC2C9C
    color: white
  .header-details
    flex-direction: column
    margin-left: 20px
    font-family: 'proxima-nova-soft'
    .name
      color: #141415
      font-weight: 600
      word-wrap: break-word
    .role
      color: #515154
      font-weight: 500
      word-wrap: break-word
  

.content
  flex: 1
  padding: 20px
  display: flex
  flex-direction: column
  overflow-x: hidden
  overflow-y: scroll

.content.dashboards
  @media (max-width: 1200px)
    padding: 0 !important
.content.orders.show, .content.orders.index, .content.carts.show
  @media (max-width: 1200px)
    padding: 0px
.pl-20
  padding-left: 20px

.pr-20
  padding-right: 20px

.p-20
  padding: 20px

.py-20
  padding-left: 20px
  padding-right: 20px

.py-m-20
  @media (max-width: 1200px)
    padding-left: 20px
    padding-right: 20px
footer
  min-height: 50px
  background: white
  padding: 20px
  display: flex
  justify-content: space-around
  position: sticky
  bottom: 0
  z-index: 999

.footer-icon
  display: flex
  flex-direction: column
  align-items: center
  svg
    height: 30px
    width: 30px

.footer-icon.active
  .footer-text
    color: $v2-primary-color
  .footer-svg
    path
      fill: $v2-primary-color
  .svg-stroke
    path
      stroke: $v2-primary-color


.footer-svg
  min-height: 30px
  path
    fill: #95989D
.svg-stroke
  path
    stroke: #95989D

.footer-text
  font-weight: 600
  font-size: 12px
  color: #95989D

.welcome-box
  display: flex
  flex-direction: column
  padding: 12px 20px 12px 0px
  
  .welcome-text
    font-weight: 700
    font-size: 20px
    line-height: 16px

  .date-text
    size: 16px
    line-height: 16px
    margin-bottom: 5px

.hidden
  opacity: 0
  height: 0
  overflow: hidden
a
  color: #7d9e1d
