.v2
  .react-pagination
    a
      margin: 0 2px
      width: 32px
      height: 32px
      border-radius: 10px !important
      text-decoration: none !important
      color: #515154
    a.--active
      color: white
    .pagination-next, .pagination-last, .pagination-first, .pagination-previous
      width: initial
