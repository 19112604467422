#main-nav
  .header-v2
    overflow-x: hidden

    @media (min-width: $screen-sm)
      overflow-x: unset

    .mobile-only
      display: block !important

      @media (min-width: $screen-md)
        display: none !important

    .desktop-only
      display: none !important

      @media (min-width: $screen-md)
        display: flex !important

    .dropdown
      &.open
        g
          opacity: 1

      font-family: 'proxima-nova-soft'

      .navbar-title
        padding: 0.5rem 1.5rem
        font-family: 'geomanistbook'
        font-size: 14px
        line-height: 2

      .navbar-item
        font-size: 14px
        padding: 0

        a
          padding: 0.8rem 1.5rem !important

        &.navbar-item--padded
          padding: 0.4rem 1.5rem !important

          a
            padding: 0 !important
            color: $lima !important

          &:first-child
            padding-top: 0.8rem!important

          &:last-child
            padding-bottom: 0.8rem!important

      .navbar-text
        line-height: 0.7

      .navbar-item--active
        a
          color: $lima !important
        path
          fill: $lima

      .empty-tick
        width: 13px

    .dropdown-menu
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2), 0px 8px 8px rgba(20, 20, 21, 0.08)
      padding: 0 0 0.4rem 0 !important
      width: 100%

      @media (min-width: 543px)
        width: 150px

      li
        margin-bottom: 0 !important
