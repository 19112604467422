$fonts-url-path: '../fonts'

$athens-grey: #d5d6d8
$barberry: #dce813
$black-russian: #141415
$gray-100: #f4f5f5
$screen-sm: 48em
$gainsboro: #dbdbdb
$gray-200: #eaeaeb
$grey-chateau: #95989d
$gun-powder: #515154
$lima: #7d9e1d
$mojo: #c3423f
$nobel: #a1a1a1
$swiss-coffee: #d6caca
$wafer: #e1d1d0
$white: #ffffff
$wine-berry: #5c164d
$purple: #864898
$lavendar-blush: #fff5fd
$white-lilac: #eaeaeb

$screen-xs: 34em
$screen-sm: 48em
$screen-md: 64em
$screen-lg: 74.9em

$v2-background-color: rgba(244, 245, 245, 1)
$v2-primary-color: rgba(125, 158, 29, 1)
