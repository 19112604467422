@mixin truncateMultilineText($lineHeight, $lineCount) {
  position: relative;
  max-height: #{$lineHeight * $lineCount};
  overflow: hidden;
  padding-right: 1rem; /* space for ellipsis */

  &::before {
    position: absolute;
    content: "...";
    bottom: 0;
    right: 0;
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    width: 1rem;
    height: 1rem;
    background: white;
  }
}