$badge-font-size: .75rem
$badge-font-weight: normal
$badge-padding-y: .25rem
$badge-padding-x: .5rem
.badge
  display: inline-block
  padding: 0.25rem 0.5rem
  font-size: 0.75rem
  font-weight: normal
  line-height: 1
  text-align: center
  white-space: nowrap
  vertical-align: baseline
  border-radius: 0.25rem
.badges
  display: flex
  flex-direction: row
  flex-wrap: wrap
  margin-left: -5px

.badges__icon
  margin-left: 5px
  margin-top: 5px

.badge
  color: rgba(0,0,0,0.7)
  border: 1px solid rgba(0, 0, 0, 0.08)

  &.badge-primary
    background: #EBF9C4

  &.badge-secondary
    background: #EAEAEB

  &.badge-warning
    background: #F7E2C3

  &.badge-danger
    background: #F7C3C3

  &.badge-info
    background: #C3EBF7
