$images-url-path: '../images'

html
  // https://css-tricks.com/elegant-fix-jumping-scrollbar-issue/
  margin-left: calc(100vw - 100.9%)

html.v2
  margin-left: 0

body.body-start
  margin: 0
  font-family: "proxima-nova-soft", Microsoft YaHei, sans-serif
  font-weight: 400
  font-size: 15px
  background: #F4F5F5
  line-height: 1.5
  text-align: left
  background-color: #EAEAEB

  h1, h2, h3, h4, h5, h6
    font-family: "sofia-pro", Microsoft YaHei, sans-serif
    font-weight: 200
    color: #141415

  h1
    font-size: 36px

  h2
    font-size: 30px

  h3
    font-size: 24px

  h4
    font-size: 22px

  h5
    font-size: 20px

  h6
    font-size: 17px

  .display-6
    font-size: 15px
    font-family: "sofia-pro",sans-serif
    font-weight: 200
    color: #141415
    border-bottom: 1px solid #EAEAEB
    padding-bottom: 7px
    margin-bottom: 12px

  p
    font-size: 15px


  a
    color: #7D9E1D

    &:hover, &:focus
      color: #BDDC63
      text-decoration: none

  label
    color: #141415

  textarea
    &.form-control
      min-height: 100px
      line-height: 21px !important
      padding: 10px 15px

  .no-margin-bottom
    margin-bottom: 0!important

  .lead
    font-size: 20px

  .text-muted
    color: #95989D
    display: inline-block
    margin-top: 10px

  .headline
    color: #141415

  .form-control
    background: #FFFFFF
    border: 1px solid #D5D6D8
    box-shadow: inset 0px 1px 1px 0px #EAEAEB
    border-radius: 3px
    padding: 0 15px
    height: 40px
    line-height: 40px
    font-size: 15px

    &:focus
      border: 1px solid #7D9E1D

    & + .input-group-addon:not(:first-child)
      border-radius: 0 3px 3px 0

  .form-control:disabled, .form-control[read-only]
    background-color: #F4F5F5 !important
    opacity: 1

  .form-group
    margin-bottom: 10px

    .input-group-addon:first-child
      border-radius: 3px 0 0 3px
      border-right: 0

  .form-control-label
    color: #95989D
    font-size: 15px
    margin: 0 0 13px
    padding: 9px 0 0 15px
    text-align: left

    .label-required
      color: #FF2200

  @media (max-width: 47.9em)
    .form-control-label
      height: 30px
      line-height: 30px

  /* base: */
  .form-control-static
    padding: 0
    margin: 0
    height: 40px
    line-height: 40px
    font-size: 13px
    color: #141415

  @media (max-width: 33.9em)
    .form-control-static
      height: auto
      line-height: 18px
      margin-bottom: 10px

  .c-input>input:checked~.c-indicator
    background-color: #7D9E1D

  .c-inputs-stacked label
    overflow: hidden!important

  .c-radio, .c-checkbox
    display: inline-block
    margin-bottom: 5px

  .custom-select
    width: 100%
    border-radius: 3px
    -webkit-border-radius: 3px
    -moz-border-radius: 3px
    background: $white url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%2395989d' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center
    background-size: 8px 10px
    line-height: 1rem

  .c-input.c-radio.custom-input.custom-radio
    margin-top: 10px

  .inputs-stacked
    margin-bottom: 30px

  .input-group
    width: 100%

    .custom-select
      width: 50%
      height: 40px
      padding: 0 15px
      line-height: 40px
      color: #141415
      font-size: 15px

      &:first-child
        border-radius: 0
        -webkit-border-top-left-radius: 3px
        -webkit-border-bottom-left-radius: 3px
        -moz-border-radius-topleft: 3px
        -moz-border-radius-bottomleft: 3px
        border-top-left-radius: 3px
        border-bottom-left-radius: 3px

      &:last-child
        border-radius: 0
        -webkit-border-top-right-radius: 3px
        -webkit-border-bottom-right-radius: 3px
        -moz-border-radius-topright: 3px
        -moz-border-radius-bottomright: 3px
        border-top-right-radius: 3px
        border-bottom-right-radius: 3px
        margin-left: -1px

  .input-group-addon
    font-size: 15px
    color: #141415
    border: 1px solid #DBDBDB
    border-radius: 3px

  \::-webkit-input-placeholder
    color: #95989D

  \:-moz-placeholder
    color: #95989D

  \::-moz-placeholder
    color: #95989D

  \:-ms-input-placeholder
    color: #95989D

  .bg-inverse.navbar-dark
    background: transparent !important
    color: #fff

  .bg-top
    background: linear-gradient(180deg, rgba(30, 4, 20, 0.3) 0%, rgba(30, 4, 20, 0.0001) 72.98%), $wine-berry
    height: 280px
    left: 0
    position: absolute
    top: 0
    right: 0
    z-index: -1

  .bg-primary, .btn-primary
    color: #fff
    background: #7D9E1D

    &:hover
      color: #fff

  .btn
    font-size: 15px
    line-height: 37px
    padding: 0 10px

  .btn-primary
    border: 1px solid #7D9E1D
    color: #fff !important

  .btn-primary.active, .btn-primary.focus, .btn-primary:active, .btn-primary:focus, .btn-primary:hover,
  .open>.btn-primary.dropdown-toggle
    background: #9ABC37
    border: 1px solid #9ABC37
    color: #fff !important

  .btn-secondary
    &.active, &.focus, &:active, &:focus, &:hover
      background: #7D9E1D
      border: 1px solid #7D9E1D
      color: #fff

  .open > .btn-secondary.dropdown-toggle
    background: #7D9E1D
    border: 1px solid #7D9E1D
    color: #fff

  .btn-danger
    color: #fff !important

    &:hover
      color: #fff !important

  .btn-sm
    font-size: 13px
    height: 36px
    line-height: 36px
    padding: 0 15px

  .btn-lg
    font-size: 17px
    height: 50px
    line-height: 50px
    padding: 0 30px

  .btn-user
    background: none
    border: none
    padding-left: 0
    padding-right: 0

  .btn-login
    border-radius: 3px
    border: 1px solid #3F4146
    padding-left: 15px
    padding-right: 15px

  .btn-naked
    background: transparent
    color: #fff

    &:focus
      outline: 0

  .btn-transparent-copy
    background: transparent
    border: none
    color: #7D9E1D
    height: 20px
    line-height: 20px

    &:hover
      color: #BDDC63

    &:focus
      outline: 0

    &:disabled:hover
      color: #7D9E1D

  .btn-transparent-danger
    background: transparent
    border-top: none
    border-bottom: none
    border-left: 1px solid #EAEAEB
    border-right: none
    color: #d9534f

    &:hover
      color: #730101

    &:focus
      outline: 0

    &.disabled:hover
      color: #d9534f

  .dropdown-item, .dropdown-menu a
    color: #141415
    height: auto!important
    line-height: normal!important
    padding: 7px 0
    display: block

  .dropdown-divider
    margin: 0

  .dropdown-item:focus, .dropdown-item:hover, .dropdown-menu a:hover
    background: #FCFFF3

  .headline-wrapper, .page-headline-wrapper
    h1
      margin: 0

    p
      margin-top: 13px
      margin-bottom: 0

  /* Modal */
  .modal-dialog
    margin-top: 120px

  .modal-header
    padding: 20px
    border: none
    background: #FCFFF3
    position: relative
    border-bottom: 1px solid #EAEAEB
    -webkit-border-top-left-radius: 3px
    -webkit-border-top-right-radius: 3px
    -moz-border-radius-topleft: 3px
    -moz-border-radius-topright: 3px
    border-top-left-radius: 3px
    border-top-right-radius: 3px

    button.close
      color: #fff
      opacity: 1
      border-radius: 50%
      width: 24px
      height: 24px
      font-size: 16px
      border: 2px solid #fff
      display: inline-block
      background: #1E0414
      position: absolute
      right: 0
      top: 0
      margin-top: -11px
      margin-right: -11px

      img
        margin-top: -6px

  .modal-title
    line-height: auto
    font-size: 18px
    float: left

  .modal-text
    float: right
    font-size: 14px
    margin: 5px 0 0 0

  button.close:hover
    color: #fff
    opacity: 1

  .modal-backdrop
    z-index: 1000
    background-color: #370B25
    opacity: 0.5

  .modal-body
    padding: 20px 6px

    .btn-primary
      background: #7D9E1D!important
      height: 46px!important
      line-height: 46px!important
      border: 1px solid #7D9E1D!important

      &:hover
        background: #9ABC37!important
        border: 1px solid #9ABC37!important

  @media (min-width: 48em)
    .modal-lg
      width: 660px

    .modal-xlg
      max-width: 1024px

  /* Breadcrumb */
  .breadcrumb
    background: none
    font-size: 13px
    color: #141415
    padding: 15px 15px
    margin: 0  0 0 -.9375rem

    > li
      margin: 0
      padding: 0

      & + li:before
        padding-right: .5rem
        padding-left: .5rem
        color: #818a91
        content: "/ "

    a
      color: #95989D

    .active
      color: #141415

  .breadcrumb>li, .card-columns .card, .pagination
    display: inline-block

  /* Nav Pills */
  .nav-pills
    margin-bottom: 30px

    .nav-link
      height: 40px
      padding: 0 20px
      line-height: 40px
      font-size: 15px
      color: #95989D

    .nav-link.active, .nav-link.active:focus, .nav-link.active:hover, .nav-item.open .nav-link,
    .nav-item.open .nav-link:focus, .nav-item.open .nav-link:hover
      background: #fff
      border: 1px solid #EAEAEB
      color: #7D9E1D

  /* Accordion */
  #accordion
    .panel-title
      padding-bottom: 17px
      border-bottom: 1px solid #EAEAEB
      margin-bottom: 14px

    .panel-collapse
      padding-bottom: 20px

  /* Container */
  .container
    width: 100%
    padding-right: 15px
    padding-left: 15px
    margin-right: auto
    margin-left: auto

  @media (min-width: 576px)
    .container
      max-width: 540px
  @media (min-width: 768px)
    .container
      max-width: 720px
  @media (min-width: 992px)
    .container
      max-width: 960px
  @media (min-width: 1200px)
    .container
      max-width: 1180px
  @media (min-width: 1400px)
    .container
      max-width: 1320px

  /* Figure */
  figure
    position: relative
    background: #1E0414
    margin-bottom: 20px

    img
      opacity: 0.8

    figcaption
      font-family: "sofia-pro",sans-serif
      font-weight: 200
      position: absolute
      width: 100%
      text-align: left
      bottom: 0
      font-size: 17px
      color: #fff
      font-weight: 300
      margin-top: -15px
      padding: 15px 15px
      background-image: linear-gradient(-180deg, rgba(30,3,19,0.00) 0%, rgba(30,3,19,0.4) 80%)

  .alert
    padding: 1rem 1.25rem

  /* Alerts */
  .alert ul
    margin-bottom: 0

  .alert-success
    background: #f3fbdc
    border:#dae7b6
    color: #737962

  .alert-danger
    background-color: #f2dede
    border-color: #ebcccc
    color: #141415

  /* Pagination */
  .pagination
    a:hover, a:focus
      color: #818a91

    >.disabled
      display: inline

      a
        position: relative
        float: left
        margin-left: -1px
        color: #0275d8
        padding: 0.24rem 0.75rem
        text-decoration: none
        background-color: #fff
        border: 1px solid #ddd 

    .page-item.active a
      background-color: #757403 !important
      border-color: #757403 !important

  .pagination>.disabled>a, .pagination>.disabled>a:focus, .pagination>.disabled>a:hover,
  .pagination>.disabled>span, .pagination>.disabled>span:focus,
  .pagination>.disabled>span:hover
    color: #818a91
    cursor: not-allowed
    background-color: #fff
    border-color: #ddd


  /* Tables */
  .table
    margin-bottom: 40px

    thead th
      border-bottom: 1px solid #F4F5F5

    td, th
      padding: 15px 20px
      font-size: 15px
      font-weight: 400
      border-top: 1px solid #F4F5F5
      color: #141415

    th
      color: #95989D
      text-transform: uppercase
      font-size: 12px

  .table-striped tbody tr
    &:nth-of-type(odd)
      background-color: #fff

    &:nth-of-type(even)
      background-color: #FCFFF3

  /* Margins */
  .mr-0
    margin-right: 0 !important

  .ml-20
    margin-left: 20px !important

  .group-actions .nav-link.btn.ml-20
    margin-left: 20px !important

  /* Switch */
  .switch-label
    color: #95989D
    margin: 0
    font-size: 15px
    padding-left: 10px

  /* Border */
  .br-0
    border-right: 0 !important

  .text-right
    text-align: right

  @media (min-width: $screen-md)
    .bg-top
      height: 210px
  .close-mobile
    display: none

.modal-backdrop.show
  z-index: 99