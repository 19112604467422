.v2
  .order-details__header
    display: flex
    align-items: center
    .order-text
      font-size: 20px
      font-weight: 600
      line-height: 24px
      letter-spacing: 0em
      text-align: left
      color: rgba(125, 158, 29, 1)
    .order-code
      font-size: 20px
      font-weight: 500
      line-height: 24px
      letter-spacing: 0em
      text-align: left
    @media (max-width: 1200px)
      display: none
  .order-details__body
    padding-left: 80px
    padding-right: 80px
    padding-top: 10px
    @media (max-width: 1200px)
      padding-left: 0 !important
      padding-right: 0 !important
      background: #fff
  .notice
    z-index: 1
    border-radius: 4px
    padding: 16px 32px 16px 32px
    box-shadow: 0px 2px 4px 0px rgba(20, 20, 21, 0.1)
    display: flex
    justify-content: space-between
    width: 100%
    .notice-text-header
      font-size: 24px
      font-weight: 400
      line-height: 28px
      letter-spacing: 0em
      text-align: left
      color: #141415
  .notice-purple-changes
    display: block
    padding: 24px 32px 16px 32px
    @media (max-width: 1200px)
      bottom: initial !important
      z-index: 2
      position: initial !important
      left: initial !important
      -webkit-transform: initial !important
      transform: initial !important
      width: initial !important
      margin-top: 5rem !important
      margin-left: 8px
      margin-right: 8px
      border-radius: 8px
    .notice-text-subheader
      font-size: 14px
  .notice.notice-purple
    background: rgba(236, 230, 240, 1)
    margin-top: 32px
    @media (max-width: 1200px)
      margin-top: 0px
      padding: 1.5rem 1rem

  .notice-draft-order
    background: rgba(226, 246, 255, 1)
    margin-bottom: 20px
    .notice-draft-order-message
      border-right: 1px solid rgba(71, 154, 189, 1)
      width: 80%
    .notice-draft-order-dates
      display: flex
      flex-direction: column
      color: rgba(71, 154, 189, 1)
      width: 20%
      .notice-draft-order-dates-text
        font-size: 16px
        font-weight: 400
        letter-spacing: 0em
        text-align: center
      .notice-draft-order-dates-value
        font-size: 16px
        font-weight: 500
        letter-spacing: 0em
        text-align: center
    @media (max-width: 1200px)
      position: initial !important
      bottom: initial !important
      left: 0px !important
      transform: initial !important
      -webkit-transform: initial !important
      width: 100% !important
      margin-bottom: 0px !important
  .notice-draft-order-container
    @media (max-width: 1200px)
      margin-top: 4rem
      padding: 0px 8px 0px 8px
      .notice-draft-order
        padding: 16px !important
      .notice-draft-order-message
        width: 100%
        border-right: none
        border-bottom: 1px solid #479abd
        padding-bottom: 24px
      .notice-draft-order-dates
        width: 100%
        padding-top: 16px
  .order-change-link
    margin-left: 5px
  .notice-error
    background: #f2dede
    flex-direction: column
  .notice-text-header-success
    text-align: center !important
    width: 100%
    display: flex
    align-items: center
  .btn-decline
    background: transparent
    border: 1px solid rgba(103, 80, 164, 1)
    width: 102px
    height: 48px
    padding: 8px 24px 8px 24px
    border-radius: 8px
    margin-right: 10px
    color: #6750a4
  .btn-accept
    width: 142px
    height: 48px
    padding: 8px 24px 8px 24px
    border-radius: 8px
    border: 1px solid rgba(103, 80, 164, 1)
    color: #6750a4
    background-color: #FFFFFF
  .order-details-twin-box
    border-top: 1px solid rgba(191, 193, 196, 1)
    border-bottom: 1px solid rgba(191, 193, 196, 1)
    display: flex
    margin-top: 20px
    @media (max-width: 1200px)
      flex-direction: column
      border-top: none
      border-bottom: none
      padding: 2rem
      background: #FFFFFF
    .order-details-box
      border-right: 1px solid rgba(191, 193, 196, 1)
      width: 50%
      padding-top: 20px
      padding-bottom: 20px
      @media (max-width: 1200px)
        width: 100%
        border-right: none
        border-bottom: 1px solid rgba(191, 193, 196, 1)
    .order-details-box.draft
      @media (max-width: 1200px)
        padding-top: 0px
    .order-details-delivery-box
      width: 50%
      padding-top: 20px
      padding-left: 20px
      @media (max-width: 1200px)
        padding-left: 0
        width: 100%
        border-bottom: 1px solid rgba(191, 193, 196, 1)
        padding-bottom: 20px
    .order-details-text-header
      font-size: 32px
      font-weight: 600
      line-height: 38px
      letter-spacing: 0em
      text-align: left
      margin-left: 10px
      @media (max-width: 1200px)
        margin-left: 0px
  .order-details-twin-box.draft
    @media (max-width: 1200px)
      margin-top: 10px
      padding: 0rem 2rem 2rem 2rem
  .order-details-twin-box.new_changes_requested, .order-details-twin-box.changes_requested
    margin-top: initial
  .order-details-group-header
    display: flex
    align-items: center
    margin-top: 10px
    margin-bottom: 24px
    @media (max-width: 1200px)
      svg
        display: none
  .order-details-group
    display: flex
    flex-direction: column
    .order-details-title
      font-size: 14px
      font-weight: 400
      line-height: 16px
      letter-spacing: 0em
      text-align: left
      color: rgba(149, 152, 157, 1)
      font-family: Roboto
    .order-details-value
      font-size: 16px
      font-weight: 400
      line-height: 22px
      letter-spacing: 0em
      text-align: left
      font-family: Roboto
    .order-details-driver
      display: flex
      flex-direction: column
  .save-driver-button
    display: flex
  .order-details-address
    display: flex
    flex-direction: column
  .order-details-basket-box
    padding-top: 20px
    padding-bottom: 20px
    @media (max-width: 1200px)
      padding-top: 2rem
  .order-details-basket-box-header
    display: flex
    align-items: center
    .order-details-basket-text-header
      font-size: 32px
      font-weight: 600
      line-height: 38px
      letter-spacing: 0em
      text-align: left
      margin-left: 10px
      @media (max-width: 1200px)
        margin-left: 0px
    @media (max-width: 1200px)
      svg
        display: none

  .basket-header
    background: rgba(244, 245, 245, 1)
    padding: 10px
    margin-top: 20px
    display: flex
    font-family: Roboto
    justify-content: space-between
    .basket-header-qty
      width: 3%
    .basket-header-item
      width: 62%
    .basket-header-label
      width: 20%
    .basket-header-price
      width: 10%
      text-align: right
  div.basket-item:nth-child(even)
    background-color: #F9F9F9
  .basket-item
    display: flex
    padding: 10px
    align-items: flex-start
    justify-content: space-between
    border-bottom: 1px solid rgba(219, 219, 219, 1)
    font-family: Roboto
    .basket-item-qty
      width: 3%
    .basket-item-name
      width: 62%
      display: flex
      flex-direction: column
      .item-name
        font-size: 20px
        font-weight: 600
        line-height: 26px
        letter-spacing: 0em
        text-align: left
    .basket-item-label
      width: 20%
      font-size: 16px
      font-weight: 400
      line-height: 22px
      letter-spacing: 0em
      text-align: left

    .basket-item-price
      width: 10%
      text-align: right
    .basket-options-label
      font-size: 14px
      font-weight: 600
      line-height: 16px
      letter-spacing: 0em
      text-align: left
      margin-top: 1rem
      @media (max-width: 1200px)
        margin-top: 24px
        margin-bottom: 4px
    .basket-options-value
      font-size: 16px
      font-weight: 400
      line-height: 20px
      letter-spacing: 0em
      text-align: left
  .basket-total
    display: flex
    padding: 10px
    font-family: Roboto
    .basket-item-grand-total
      font-size: 24px
      font-weight: 500
      line-height: 28px
      letter-spacing: 0em
      text-align: left
      width: 3%
    .basket-item-grand-total-text
      width: 70%
      font-size: 16px
      font-weight: 500
      line-height: 22px
      letter-spacing: 0em
      text-align: left
    .basket-item-total
      width: 25%
      display: flex
      flex-direction: column
      .basket-item-total-row
        display: flex
        border-bottom: 1px solid rgba(234, 234, 235, 1)
        padding-top: 10px
        padding-bottom: 8px
        .basket-item-total-text
          width: 60%
          font-size: 16px
          font-weight: 400
          line-height: 22px
          letter-spacing: 0em
          text-align: left
        .basket-item-total-value
          font-size: 18px
          font-weight: 600
          line-height: 22px
          letter-spacing: 0em
          text-align: right
          color: rgba(20, 20, 21, 1)
          width: 40%
        .basket-item-total-value.grand-total-value
          font-size: 32px
          font-weight: 600
          line-height: 38px
          letter-spacing: 0em
          text-align: right
      .basket-item-total-row-last
        border-bottom: 2px solid #BFC1C4 !important
      .basket-item-total-row.grand-total-value
        border-bottom: none
        .basket-item-total-text
          font-family: Roboto
          font-size: 16px
          font-weight: 600
          line-height: 22px
          letter-spacing: 0em
          text-align: left

      .basket-item-total-row-gst
        padding-top: 16px
        padding-bottom: 16px
        border-bottom: 2px solid rgba(149, 152, 157, 1) !important
  .basket-delivery-instructions
    display: flex
    flex-direction: column
    background: rgba(244, 245, 245, 1)
    min-height: 90px
    padding: 24px 32px 24px 32px
    border-radius: 8px
    margin-top: 20px
    margin-bottom: 20px
    .basket-delivery-instructions-text
      font-size: 14px
      font-weight: 600
      line-height: 16px
      letter-spacing: 0em
      text-align: left
    .basket-delivery-instructions-value
      font-size: 16px
      font-weight: 400
      line-height: 22px
      letter-spacing: 0em
      text-align: left
      padding-top: 4px
  .order-details-billing-box
    padding-bottom: 20px
    padding-top: 20px
    .order-details-billing-box-toggle
      cursor: pointer
    .order-details-billing-group-header
      margin-bottom: 48px
      @media (max-width: 1200px)
        margin-bottom: 0px
        border-bottom: 1px solid rgba(219, 219, 219, 1)
    @media (max-width: 1200px)
      padding: 1rem 1rem 2rem 1rem
      background: #FFFFFF
      .order-details-basket-box-header
        margin-bottom: 20px
    @media (min-width: 1200px)
      border-bottom: 1px solid rgba(219, 219, 219, 1)
  .order-details-billing-group-header
    display: flex
    justify-content: space-between
  .order-details-show-more
    font-size: 16px
    font-weight: 600
    line-height: 19px
    letter-spacing: 0em
    text-align: left
    color: rgba(20, 20, 21, 1)
    margin-right: 10px
    @media (max-width: 1200px)
      display: none
  .order-details-history-box
    border-bottom: 1px solid #bfc1c4
    padding-bottom: 20px
    padding-top: 20px
    .order-details-basket-box-header
      margin-bottom: 48px
      @media (max-width: 1200px)
        margin-bottom: 0px
    @media (max-width: 1200px)
      padding: 0rem 1rem 2rem 1rem
      background: #FFFFFF
  .order-details-group-text
    display: flex
    flex-direction: column
  .order-details-basket-text-subheader
    font-size: 16px
    font-weight: 400
    line-height: 22px
    letter-spacing: 0em
    text-align: left
    margin-left: 10px
    @media (max-width: 1200px)
      margin-left: 0px
      margin-top: 20px
  .order-details-group-body
    margin-top: 20px
    font-family: Roboto
  .billing-details-item
    display: flex
    justify-content: space-between
    border-bottom: 1px solid rgba(219, 219, 219, 1)
    margin-top: 10px
    .billing-details-item-value
      font-size: 16px
      font-weight: 500
      line-height: 22px
      letter-spacing: 0em
      text-align: right
  .billing-details-item-total
    border-bottom: none
    .billing-details-item-value
      font-size: 24px
      font-weight: 600
      line-height: 28px
      letter-spacing: 0em
      text-align: right
    .billing-details-item-text
      font-size: 20px
      font-weight: 400
      line-height: 22px
      letter-spacing: 0em
      text-align: left
  .history-details-header
    display: flex
    font-size: 14px
    font-weight: 400
    line-height: 16px
    letter-spacing: 0em
    text-align: left
    margin-bottom: 10px
    color: rgba(149, 152, 157, 1)
    .history-details-header-date
      width: 20%
    .history-details-header-actions
      width: 50%
    .history-details-header-user
      width: 20%
    .history-details-header-view
      width: 10%
  .history-details-body
    display: flex
    font-size: 16px
    font-weight: 400
    line-height: 22px
    letter-spacing: 0em
    text-align: left
    border-bottom: 1px solid rgba(219, 219, 219, 1)
    padding-bottom: 10px
    .history-details-body-date
      width: 20%
    .history-details-body-actions
      width: 50%
    .history-details-body-user
      width: 20%
    .history-details-body-view
      width: 10%
  .modal-order-history, .modal-decline-form
    @media (max-width: 1200px)
      margin: 0px
    .modal-content
      border-radius: 20px
      @media (max-width: 1200px)
        border-radius: 0px
    .modal-header
      display: flex
      justify-content: space-between
      width: 100%
      background: #fcfff3
      border-top-left-radius: 20px
      @media (max-width: 1200px)
        background: initial
      .modal-title
        width: 90%
      button.close
        color: #fff
        opacity: 1
        border-radius: 50%
        width: 24px
        height: 24px
        font-size: 16px
        border: 2px solid #fff
        display: inline-block
        background: #1e0414
        position: absolute
        right: 0
        top: 0
        margin-top: -11px
        margin-right: -11px
        @media (max-width: 1200px)
          display: none
      button.close-mobile
        @media (max-width: 1200px)
          display: block
          background: white
          border: none
          margin-right: 10px
          align-items: center
          display: flex
        @media (min-width: 1200px)
          display: none
    .modal-body
      @media (max-width: 1200px)
        height: 83vh
    .basket-item-price
      width: 5%
  .modal-order-history
    .modal-body
      @media (max-width: 1200px)
        height: 88vh
  .basket-total
    display: flex
    padding: 10px
    .basket-item-grand-total
      font-size: 24px
      font-weight: 500
      line-height: 28px
      letter-spacing: 0em
      text-align: left
      width: 5%
    .basket-item-grand-total-text
      width: 70%
      font-size: 16px
      font-weight: 500
      line-height: 22px
      letter-spacing: 0em
      text-align: left
    .basket-item-total
      width: 25%
      display: flex
      flex-direction: column
      .basket-item-total-row
        display: flex
        border-bottom: 1px solid rgba(234, 234, 235, 1)
        padding-top: 16px
        padding-bottom: 8px
        .basket-item-total-text
          width: 60%
          font-size: 16px
          font-weight: 400
          line-height: 22px
          letter-spacing: 0em
          text-align: left
          @media (max-width: 1200px)
            font-family: Roboto
            font-size: 14px
            font-weight: 300
            line-height: 22px
            letter-spacing: 0em
            text-align: left
        .basket-item-total-value
          font-size: 18px
          font-weight: 500
          line-height: 22px
          letter-spacing: 0em
          text-align: right
          color: rgba(20, 20, 21, 1)
        .basket-item-total-value.grand-total-value
          font-size: 32px
          font-weight: 600
          line-height: 38px
          letter-spacing: 0em
          text-align: right
      .basket-item-total-row.grand-total-value
        border-bottom: none
  .order-details-header-buttons
    display: flex
    justify-content: end
    position: relative
    .button-print
      width: 95px
      height: 32px
      padding: 0px 16px 0px 14px
      border-radius: 8px
      display: flex
      align-items: center
      justify-content: space-around
      color: rgba(81, 81, 84, 1)
      border: 1px solid rgba(81, 81, 84, 1)
      svg
        path
          fill: rgba(81, 81, 84, 1)
      @media (max-width: 1200px)
        display: none
    .button-print:hover
      color: white
  .order-details-button-hover-group
    position: absolute
    padding-top: 40px
    display: flex
    flex-direction: column
    .button-print-delivery-order
      margin-bottom: 10px
      display: flex
      align-items: center
      border: 1px solid rgba(244, 245, 245, 1)
      width: 112px
      height: 43px
      border-radius: 8px
      display: flex
      align-items: center
      justify-content: center
    .button-print-label
      display: flex
      align-items: center
      display: flex
      align-items: center
      border: 1px solid rgba(244, 245, 245, 1)
      width: 112px
      height: 43px
      border-radius: 8px
      display: flex
      align-items: center
      justify-content: center
  .print-icon
    svg
      path
        fill: black
  .order-details-basket-box-carts-desktop
    padding-top: 39px
    @media (max-width: 1200px)
      display: none
  
  .order-details-basket-box-carts-mobile
    @media (max-width: 1200px)
      padding-left: 16px
      padding-right: 16px
      background: #FFFFFF
    @media (min-width: 1200px)
      display: none
    .order-details-basket-box-header
      svg
        display: block
      .order-details-basket-text-header
        margin-left: 15.5px
      margin-bottom: 16px
    .basket-body
      font-family: Roboto
      border-bottom: 1px solid #bfc1c4
      div.basket-item:nth-child(even)
        background-color: #F9F9F9
      .basket-item
        background: #FFFEF9
        margin-top: 20px
        padding: 24px 24px 16px 24px
        border-radius: 6px
        border: 1px solid rgba(149, 152, 157, 1)
        align-items: initial
        flex-direction: column
        @media (max-width: 1200px)
          margin-top: 8px
        .basket-item-qty
          width: 100%
          font-size: 16px
          font-weight: 300
          line-height: 22px
          letter-spacing: 0em
          text-align: left

        .basket-item-name
          width: 100%
        .basket-item-label
          width: 100%
          border-top: 1px solid #bfbfbf
          padding-top: 16px
          margin-top: 16px
          display: flex
          flex-direction: column
        .basket-item-price
          width: 100%
    .basket-item-total
      width: 100%
    .basket-delivery-instructions
      padding: 16px
      border-radius: 12px
      background: rgba(244, 245, 245, 1)
      margin-bottom: 64px
      @media (max-width: 1200px)
        background: #F4F5F5
  .order-details-group-body-desktop
    @media (max-width: 1200px)
      display: none
  .order-details-group-body-mobile
    @media (min-width: 1200px)
      display: none
    .history-details-body
      padding: 12px 16px 12px 16px
      border-radius: 8px
      background: rgba(255, 254, 249, 1)
      box-shadow: 0px 2px 4px 0px rgba(20, 20, 21, 0.24)
      display: flex
      flex-direction: column
      .history-details-body-date
        width: 100%
      .history-details-body-actions
        margin-top: 20px
        width: 100%
        display: flex
        justify-content: space-between
  .notice
    font-family: Roboto
    @media (max-width: 1200px)
      position: fixed
      bottom: 90px
      width: 95%
      display: flex
      flex-direction: column
      height: initial
      left: 50%
      transform: translateX(-50%)
      .notice-button-group
        display: flex
        justify-content: end
        margin-top: 20px
    .notice-button-group-change-requested
      display: flex
      justify-content: end
      margin-top: 2rem
      margin-bottom: 1rem
  .pointer
    cursor: pointer
  .notice-text-group
    display: flex
    flex-direction: column
  .label-name
    display: flex
    font-size: 16px
    font-weight: 300
    line-height: 1.8
    letter-spacing: 0em
    text-align: left

  .label-span
    font-size: 14px
    font-weight: 600
    line-height: 16px
    letter-spacing: 0em
    text-align: left
    margin-bottom: 8px
  .custom-checkbox-v2
    accent-color: #7d9e1d
    height: 18px
    width: 38px
  .mt-16
    margin-top: 16px
  .mt-8
    margin-top: 8px
  .order-diff__badge
    border-color: #EE8434
    background-color: #FFF1D8
    color: #EE8434
    min-height: 39px
    align-items: center
    display: flex
    width: fit-content
    min-width: 141px
    justify-content: center
    text-transform: initial
    font-family: "proxima-nova-soft"
    font-size: 16px
    border-radius: 30px
    margin-top: 1rem
    margin-bottom: 8px
    font-weight: 600
  .history-with-diff-card-container
    display: flex
    align-items: center
    gap: 8px
    margin-top: 8px
    svg
      width: 48px
      height: 48px
      @media (max-width: 1200px)
        width: initial
        height: initial
        transform: rotate(90deg)
    @media (max-width: 1200px)
      flex-direction: column
      gap: 4px
  .history-with-diff-card-container-changes.changed-container
    @media (max-width: 1200px)
      padding-bottom: 24px
      margin-bottom: 24px
      background-image: linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%)
      background-position: bottom
      background-size: 12px 2px
      background-repeat: repeat-x
  .history-with-diff-card-container-changes.changed-container.last-changed-container
    background-image: initial !important
    background-position: initial !important
    background-size: initial !important
    background-repeat: initial !important
    padding-bottom: initial
    margin-bottom: initial
  .history-with-diff-card-container-changes
    margin-top: 8px
  .history-with-diff-card-container-changes:after
    letter-spacing: 40px
  .history-with-diff-card
    background: white
    width: 100%
    border-radius: 8px
    .history-with-diff-card-body-delivery-changes
      .order-diff__table-content
        padding: 16px
  .order-diff__table-content
    padding: 16px
  .history-with-diff-card-changes
    min-height: initial
  .history-with-diff-card-header
    padding: 6px 0 6px 16px
    background: #f4f5f5
    border-top-left-radius: 8px
    border-top-right-radius: 8px
    .history-with-diff-card-header-title
      font-size: 16px
  .history-with-diff-card-body
    .history-with-diff-card-body-container
      margin-top: 16px
      margin-bottom: 16px
      .order-diff__table-content
        padding: initial
    .order-diff__table-content--with-bg
      background: white
      border-radius: 8px
      .order-diff__table-content
        @media (max-width: 1200px)
          padding: 16px
    .col-md-5.order-diff__table-content--with-bg
      float: initial
      padding-right: initial
      padding-left: initial
    .col-md-5
      width: 100%
    .offset-md-2
      margin-left: 7%
    .col-md-2
      width: initial
      margin-right: 16px
    .option-title
      margin-left: 12px !important
      font-size: 16px
    .additional-instructions-text
      font-size: 16px
    .option-qty
      font-size: 16px
    @media (max-width: 1200px)
      .offset-md-1
        margin-left: 10%
      .col-md-1
        width: 10%
  .diff-text-delivery-label
    font-size: 16px
  .diff-text-delivery-changes
    margin-top: 12px
    width: fit-content
  .history-with-diff-card-changes-added, .history-with-diff-card-changes-removed
    .order-diff__table-content
      width: 40%
      @media (max-width: 1200px)
        width: 100%
    .diff-order-title
      .col-md-11
        width: initial
  .history-with-diff-card-changes-removed
    .no-gutters>.col, .no-gutters>[class*=col-]
      text-decoration: line-through
    min-height: initial
  .changed
    background-color: #FEFFB6
  .diff-order-title
    font-size: 16px


.react-pdf__Page__textContent.textLayer
  display: none
.react-pdf__Page__annotations.annotationLayer
  display: none
[id^="TermsApp-react-component-"]
  width: 100vw

.term-container
  display: flex
  min-height: 100vh
  background-color: #f0f0f0
  justify-content: center
  align-items: center
  @media (max-width: 1200px)
    justify-content: initial
    align-items: initial
    overflow: auto
