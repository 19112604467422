
.logo-center
  display: flex
  justify-content: center
  align-items: center
  padding: 32px 24px 32px 24px


.v2 .form-control
  border: 1px solid rgba(242, 245, 232, 1)
  height: 56px
  radius: 8px

.v2 .form-control:focus
  border: 1px solid $v2-primary-color
  box-shadow: 0px 0px 0px 3px rgba(125, 158, 29, 0.25)

.v2 .forgot-text
  color: $v2-primary-color

.v2 .btn-primary
  background: $v2-primary-color
  height: 48px
  border: 1px

.v2 .btn
  height: 48px
  border: 1px

.v2 .btn-default
  background: #FFFFFF
  color: black
  border: 1px solid #EAEAEB

.v2 .btn-primary:hover
  background: $v2-primary-color
  height: 48px
  border: 1px

.v2 nav.container
  max-width: 1320px

.v2 .btn-naked
  background-color: transparent
  box-shadow: initial

.v2 
  .btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus
    outline: initial
    outline-offset: initial

.v2
  .container
    width: 540px
    max-width: 100%
