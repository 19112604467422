.v2
  .react-pagination
    justify-content: flex-start
    padding-left: 10px
  .order-filters
    display: flex
    justify-content: space-between
  .order-search
    border: 1px solid #95989D
    width: 320px
    height: 40px
    top: 168px
    left: 229px
    border-radius: 4px
    padding: 10px 40px 10px 40px
    background-image: url('#{$images-url-path}/search-icon.svg')
    background-size: 20px
    background-position: 10px center
    background-repeat: no-repeat
  .order-search:focus
    outline: none
    border: 1px solid #7d9e1d
    box-shadow: 0px 0px 0px 3px #7D9E1D40
  .order-search-filter
    display: flex
    flex-direction: column
    .search-text
      font-weight: 600
      margin-bottom: 5px
  .order-date-filters
    .filters-text
      font-weight: 600
      margin-bottom: 5px
    .filters-date-group
      display: flex
      align-items: center
      margin-top: 5px
  .order-filters
    display: flex
  .order-date
    background: #FFF
    padding: 10px
    border: 1px solid #95989D
    border-radius: 25px
    height: 40px
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    input
      display: none
    span
      margin-left: 5px
      margin-right: 5px
  .order-date-filter--active
    .order-date
      border: 1px solid #7D9E1D
      color: #7D9E1D
    svg
      path
        fill: #7D9E1D !important
  .clear-filters
    cursor: pointer
    color: #7D9E1D
    font-size: 14px
  .active-tags
    margin-top: 35px
    display: flex
    gap: 10px
    border-bottom: 1px solid #EAEAEB
    padding-bottom: 16px
    margin-bottom: 10px
    align-items: center
  .filter-tag
    width: fit-content
    height: 100%
    padding-left: 8px
    padding-right: 8px
    padding-top: 4px
    padding-bottom: 4px
    background: #EAEAEB
    border-radius: 4px
    justify-content: flex-start
    align-items: center
    gap: 8px
    display: inline-flex
    .filter-tag-text
      color: #515154
      font-size: 12px
      font-family: 'proxima-nova-soft'
      font-weight: 400
      word-wrap: break-word

  .desktop-order-tabs
    display: flex
    margin-top: 32px
    .desktop-order-tab
      display: flex
      flex-direction: column
      padding: 12px 8px 12px 8px
      height: 70px
      width: 90px
      cursor: pointer
      span.title
        font-weight: 600
        font-size: 12px
      span.value
        font-weight: 600
        font-size: 20px
    .desktop-order-tab.active
      border-bottom: 2px solid #7d9e1d
  .loader-container
    display: flex
    width: 100%
    justify-content: center
  .loader
    width: 36px
    height: 36px
    border: 5px solid #999999
    border-bottom-color: transparent
    border-radius: 50%
    display: inline-block
    box-sizing: border-box
    animation: rotation 1s linear infinite
    @keyframes rotation
      0%
        transform: rotate(0deg)
      100%
        transform: rotate(360deg)
  .chip
    height: 22px
    line-height: 150%
    width: fit-content
    border-radius: 15px
    border: 1px solid #95989D
    padding: 2px 8px 2px 8px
    border-color: #95989D
    color: #95989D
    display: flex
    align-items: center
    font-size: 12px
    font-weight: 600
    background: #F4F5F5
  .chip:before
    content: "•"
    display: inline-block
    width: 10px
    height: 19px
    transform: scale(2)
  .chip.newly_placed
    color: #479ABD
    border-color: #479ABD
    background: #EFFAFF
  .chip.declined
    color: #C3423F
    border-color: #C3423F
    background: #FFEFEF
  .chip.accepted
    color: #7D9E1D
    border-color: #7D9E1D
    background-color: #F9FDE1
  .chip.draft, .chip.pending, .chip.assign_outlet
    color: #37738E !important
    border-color: #37738E
    background: #F4F5F5
  .chip.confirmed
    color: #7C830A
    border-color: #7C830A
    background: #F8F9F2
  .chip.re_confirmed
    color: #7C830A
    border-color: #7C830A
    background: #F0F4D8
  .chip.cancelled_by_customer, .chip.cancelled_by_vendor
    color: #C3423F
    border-color: #C3423F
    background: #FFEFEF
  .chip.new_changes_requested, .chip.new_with_changes, .chip.changes_requested
    color: #EE8434
    border-color: #EE8434
    background: #FFF1D8
  #order-list-dashboard-desktop
    .table-container
      table
        color: #141415
        width: 100%
        tr
          height: 50px
  #order-list-dashboard-mobile.order-lists
    overflow: auto
    .order-text
      font-weight: 600
      font-size: 18px
  .mobile-order-header-tab
    height: 21px
    width: fit-content
    border-radius: 15px
    border: 1px solid #515154
    padding: 4px 12px 4px 12px
    border-color: #515154
    color: #515154
    display: flex
    align-items: center
    font-size: 14px
    font-weight: 500
    margin-right: 10px
    white-space: nowrap
    span
      margin-left: 5px
      font-weight: 600
  .mobile-order-header-tab.active
    color: white
    background-color: $v2-primary-color
    border: 1px solid $v2-primary-color
    path
      fill: white
  .mobile-order-header-tab.count-filters
    color: $v2-primary-color
    background-color: white
    border: 1px solid #7D9E1D
    svg
      path
        fill: $v2-primary-color
  .mobile-order-header-tabs
    padding: 5px 0 5px 0
    justify-content: space-between
    margin-top: 15px
    overflow: auto
  .mobile-search-input
    width: 271px
    height: 38px
    padding: 12px 16px 12px 16px
    border-radius: 8px
    background: #F4F5F5
    border: 1px solid #F4F5F5
  .mobile-search-input:focus, .mobile-search-input:active
    background: #F4F5F5
    border: 1px solid #F4F5F5
    outline: none
    box-shadow: 0px 0px 0px 3px #7D9E1D40
    border: 1px solid #7D9E1D
  .mobile-order-header-titles
    justify-content: space-between
    height: 38px
    align-items: center
  .mobile-order-header-titles.filter-modal
    padding: 20px
    span:first-child
      flex: 0.7
    span:nth-child(2)
      flex: 1
  .filter-modal-content
    margin-top: 70px
    height: 80vh
    display: flex
    flex-direction: column
    overflow: auto
    .header-text
      color: #141415
      font-weight: 600
      font-size: 16px
      margin: 20px
    .sort-by-row
      background-color: #FFFFFF
      display: flex
      justify-content: space-between
      padding: 10px 20px 10px 20px
      .status-dropdown-item-label
        margin-bottom: initial
    .sort-by-row-first
      justify-content: initial
      gap: 8px
      align-items: center
  .radio-button
    background-color: $v2-primary-color
    color: $v2-primary-color
    border-color: $v2-primary-color
    accent-color: $v2-primary-color
    height: 18px
    width: 38px
  .order-filter-mobile-footer-button
    padding: 20px
    position: fixed
    width: 100%
    bottom: 0
    display: flex
    gap: 8px
    .btn-primary
      width: 80%
    .date-picker-container
      flex-direction: column
      align-items: center
      display: flex
      width: 100%
      .button-container
        width: 100%
        gap: 8px
        margin-top: 16px
      button
        width: 50% !important
  .order-filter-mobile-footer-button.full
    .btn-primary
      width: 100%
  .filter-date-mobile
    display: none
  .react-datepicker
    margin-top: 30px
    border: none !important
    height: 80vh
    overflow: auto
    width: 100%
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name
    width: 13vw
    line-height: 13vw
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range
    border-radius: 32px
    background-color: #7D9E1D
    color: white
  .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover
    border-radius: 32px
    background-color: #7D9E1D
    color: white
  .react-datepicker__month-container
    width: 100%
    margin-top: 20px
  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected
    background-color: initial
    color: initial
  .react-datepicker__day.react-datepicker__day--today
    border: 1px solid #141415
    background: white
    color: black
    border-radius: 32px

  .react-datepicker__header
    background-color: initial !important
    border-bottom: none !important
  .react-datepicker__current-month
    display: flex
    margin-left: 2.5rem
    color: black
    font-weight: 600
    font-size: 14px
  .react-datepicker__day--in-range
    background-color: #F2F5E8
    color: black
  .react-datepicker__day--range-start, .react-datepicker__day--range-end
    background-color: #7D9E1D !important
    color: white !important
    border-radius: 32px !important
    border: none !important
  .react-datepicker__day.react-datepicker__day--selected.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end.react-datepicker__day--today
    background-color: #7D9E1D !important
    color: white !important
    border-radius: 32px !important
    border: none !important
  .react-datepicker__navigation--previous
    right: 70px
    left: initial
  .react-datepicker__navigation--next
    right: 28px
  button:focus
    outline: initial
  .react-datepicker__header
    margin-top: 20px
  .react-datepicker__current-month
    font-size: 24px
    font-family: 'proxima-nova-soft'
    font-weight: 600
  .react-datepicker__day-names
    margin-top: 8px
    font-weight: 600
  .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before
    border-color: black !important
  .react-datepicker
    overflow: auto
  .react-datepicker__month-container
    margin-top: -35px
  .react-datepicker__month-container + .react-datepicker__month-container
    margin-top: 0px
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range)
    background-color: #7D9E1D
    color: white
    border-radius: 32px
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name
    margin: initial !important
  .react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover
    background-color: #7D9E1D
    color: white
  .react-datepicker__day.react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--in-range
    border-radius: 32px
  .react-datepicker__day.react-datepicker__day--in-range
    border-radius: 0
  .react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--range-end.react-datepicker__day--in-range
    border-radius: 32px
  .react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--today
    background-color: initial
  .order-card
    width: 300px
    height: 164px
    padding: 16px
    border-radius: 6px
    box-shadow: 0px 8px 16px rgba(20, 20, 21, 0.08)
    margin-top: 8px
    background-color: white
    color: #141415
    .order-code
      font-weight: 400
      font-size: 14px
      line-height: 16.69px
      color: #141415
    .order-card-header
      justify-content: space-between
      align-items: center
    .order-card-body
      margin-top: 20px
      align-items: center
      .order-content
        display: flex
        flex-direction: column
        margin-left: 10px
        .order-time
          font-size: 16px
          font-weight: 600
          line-height: 19px
          letter-spacing: 0em
          text-align: left
        .delivery-type
          font-size: 14px
          font-weight: 400
          line-height: 17px
          letter-spacing: 0em
          text-align: left
        .order-outlet
          font-size: 14px
          font-weight: 400
          line-height: 17px
          letter-spacing: 0em
          text-align: left

    .order-card-footer
      justify-content: end
      align-items: center
      .no-items
        font-size: 14px
        font-weight: 400
        line-height: 17px
        letter-spacing: 0px
        text-align: right
        margin-right: 10px
      .total-value
        font-size: 16px
        font-weight: 600
        line-height: 19px
        letter-spacing: 0em
        text-align: right
    .order-calendar-icon
      height: 60px
      width: 60px
      border-radius: 8px
      border: 2px solid #DBDBDB
      display: flex
      flex-direction: column
    .order-calendar-text-day
      font-weight: 600
      font-size: 16px
      line-height: 19.24px
      text-align: center
    .order-calendar-text-date
      font-weight: 400
      font-size: 12px
      line-height: 14.3px
      text-align: center
    .order-calendar-icon-header
      height: 13px
      background-color: #dbdbdb
      margin-bottom: 5px
  .order-card.order-card-full
    width: 100%
  .order-card.action-required-card
    min-width: 300px
    margin-right: 12px
    margin-top: 0px
  .load-more-button
    display: flex
    justify-content: center
    margin-top: 20px
    margin-bottom: 20px
  .mobile-order-list
    padding: 32px 16px 0px 16px
    .order-card.order-card-full:first-child
      margin-top: 80px
  .status-dropbtn
    background-color: #3498DB
    color: white
    padding: 16px
    font-size: 16px
    border: none
    cursor: pointer

  .status-dropdown
    position: relative
    display: inline-block
  .status-dropdown-category-text-title
    padding-top: 20px
    padding-bottom: 16px
  .status-dropdown-content
    display: none
    position: absolute
    background-color: #FFFFFF
    width: max-content
    height: 500px
    overflow: auto
    box-shadow: 0px 2px 4px 0px #1414151A
    z-index: 3
    padding: 16px
    border-radius: 8px
    .status-dropdown-category
      display: flex
      flex-direction: column
      border-bottom: 1px solid #BFC1C4
      margin-bottom: 12px
    .status-dropdown-item
      display: flex
      margin-top: 12px
      margin-bottom: 12px
      align-items: center
    .status-dropdown-category-text
      font-size: 16px
      font-weight: 600
      font-family: 'proxima-nova-soft'

    .status-dropdown-item-label
      width: 100%
      font-weight: 500
    label
      margin-bottom: initial
  .status-dropdown-content.show
    display: block
    margin-top: 10px
    -webkit-backdrop-filter: blur(5px)
  .status-tag-number
    padding: 0px 8px 0px 8px
    border: 1px
    color: #515154
    border-radius: 20px
    background-color: #EAEAEB
    height: 26px
    min-width: 26px
    display: inline-flex
    justify-content: center
    align-items: center
    margin-left: 8px
  .status-tag-number.active
    background-color: #7d9e1d
    color: white
  .mobile-header-icon
    border: 1px solid #DBDBDB
    border-radius: 32px
    display: flex
    align-items: center
    padding: 4px 8px
    min-width: 44px
    justify-content: center
    height: 36px
    background: #FCFFF3
  .mobile-header-icon.active
    border: 1px solid #7d9e1d
    path
      fill: #7d9e1d
  .mobile-header-icon-container
    display: flex
    gap: 8px
  .input-with-icon
    position: relative
    display: inline-block

  .input-with-icon input
    padding-right: 30px

  .input-with-icon .icon
    position: absolute
    top: 50%
    right: 10px
    transform: translateY(-50%)
      /* Additional styling for the icon (e.g., color, size) can be added here */
  .orders-count-desktop
    display: flex
    justify-content: end
    margin-bottom: 10px
    font-weight: 600
  .orders-count-mobile
    display: flex
    justify-content: end
    margin-bottom: 32px
    margin-top: 60px
    font-weight: 600
  .sort-icon
    margin-left: 8px
  .sort-active
    path
      fill: $v2-primary-color
      stroke: $v2-primary-color
      color: $v2-primary-color
  .pagination-container
    border-top: 1px solid #eceded
    margin-top: 10px
    padding-top: 10px
  .datepicker-cta
    background-color: white
    justify-content: end
    display: flex
    gap: 8px
    padding-right: 10px
    border-bottom-right-radius: 8px
    border-bottom-left-radius: 8px
    button, button:hover
      height: 32px
      display: flex
      align-items: center
      width: 77px

  .status-backdrop
    z-index: 3
    opacity: 0.5 !important
  .line-filter-mobile
    height: 1px
    border: 0
    margin-top: 30px
    margin-bottom: 20px
    border-top: 1px solid #BFC1C4
    margin-left: 24px
    margin-right: 24px
  .mobile-filter-text-display
    color: $v2-primary-color
  .sort-by-row-end-container
    gap: 8px
    display: flex
    align-items: center
  .datepicker-mobile-close
    margin-top: 30px
    display: flex
    justify-content: end
    margin-right: 20px
  .empty-active-tags
    margin-top: 16px
