$silver: #cccccc
$black: #141415
$gray-100: #f4f5f5

@mixin addressBlock()
  border: 2px solid transparent
  border-radius: 4px
  margin-bottom: 15px
  cursor: pointer

  &:hover
    border: 2px solid $green

@mixin newAddressBlock()
  @include addressBlock

  .address-block__box
    align-items: center
    display: flex
    justify-content: center

    .btn-small
      font-size: 13px
      line-height: 30px

@mixin addressBlockBtn()
  &.disabled
    background-color: $white
    opacity: 0.4
    border-color: $silver

.address-block
  @include addressBlock

.address-block--no-footer
  @include addressBlock

  .address-block__box
    height: 197px

.address-block__box
  background: $white
  border-radius: 4px
  border: 1px solid $gray-300
  color: $black
  font-size: 14px
  height: 227px
  min-height: 150px
  padding: 10px 15px 0
  position: relative

.address-block--new
  @include newAddressBlock

.address-block--new-no-footer
  @include newAddressBlock

  .address-block__box
    height: 197px

.address-block__actions
  display: flex
  position: absolute
  top: 10px
  right: 10px

@mixin addressBlockAction()
  background-repeat: no-repeat
  background-size: 14px 14px
  cursor: pointer
  height: 15px
  margin-left: 10px
  width: 15px

.address-block__delete-btn
  @include addressBlockBtn

.address-block__edit-btn
  @include addressBlockBtn

.address-block__content
  height: 80%
  position: relative

.address-block__content-instructions
  @include truncateMultilineText(21px, 2)

.address-block__footer
  align-items: center
  background-color: $gray-100
  bottom: 0
  display: flex
  height: 48px
  justify-content: flex-end
  margin-left: -15px
  padding: 0 20px
  position: absolute
  width: 100%

  .btn
    margin-left: 8px

.address-block__selected-icon
  position: absolute
  right: 0

.address-block--selected
  border: 2px solid $green

.address-block--disabled
  cursor: not-allowed
.address-card
  .address-card__body
    display: flex

  .address-card__content
    cursor: pointer
    flex: 1

  .address-card__action-edit
    cursor: pointer
    list-style: none

    a
      background-image: url('#{$images-url-path}/icon-edit.png')
      background-position: 50% 50%
      background-repeat: no-repeat
      background-size: 14px 14px
      display: inline-block
      height: 14px
      text-indent: -9999px
      width: 14px

.bg-light-red
  .address-card__content
    cursor: default

