.modal-backdrop
  background-color: rgba(0, 0, 0, 0.5)
.modal-dialog.align-left
  position: fixed
  top: 0
  left: 0
  height: 100%
  margin: 0
  transform: translateX(-100%)
  transition: transform 0.3s ease-out
  transform: translateX(0)
  width: 100%
  height: 100%
  animation: slideFromLeft 0.3s forwards
  @media (max-width: 1200px)
    animation: initial
    transform: initial
    transition: initial
  .modal-content
    width: 100%
    height: 100%
  .modal-body
    padding: initial
@keyframes slideFromLeft
  from
    transform: translateX(-100%)
  to
    transform: translateX(0)

@keyframes slideToRight
  from
    transform: translateX(0)
  to
    transform: translateX(-100%)

.modal.hide .modal-dialog.align-left
  animation: slideToRight 0.3s forwards



.search-container
  display: inline-block
  width: 90%

.search-input
  padding: 10px 40px 10px 30px
  background-image: url('#{$images-url-path}/search-icon.svg')
  background-size: 20px
  background-position: 10px center
  background-repeat: no-repeat
  width: 80%
  border: 1px solid #F2F5E8
  border-radius: 8px
  height: 40px
.search-input:focus
  outline: none
  border: 1px solid #7d9e1d
  box-shadow: 0px 0px 0px 3px #7D9E1D40
.modal-backdrop.fade.in
  @media (max-width: 1200px)
    background-color: transparent !important
    visibility: hidden

.outlet-selector-mobile
  padding: 20px


.modal-backdrop.fade.in + .footer
  display: none
