.v2
  #order-list-dashboard-mobile
    @media (min-width: 1200px)
      display: none !important
    .header
      align-items: center
      .order-text
        font-weight: 600
        font-size: 20px
      .tabs
        justify-content: space-between
        align-items: center
        background-color: #F4F5F5
        border: 1px
        border-radius: 8px
      .tab
        width: 100%
        justify-content: space-between
        align-items: center
        padding: 10px
      .tab.active
        border: 1px
        border-radius: 8px
        background-color: #FFFFFF
  #order-list-desktop-container
    @media (max-width: 1200px)
      display: none !important
  .order-list-dashboard-desktop-header
    @media (max-width: 1200px)
      display: none !important
    font-weight: 600
    font-size: 20px
    margin-right: 20px
    margin-top: 1rem
    margin-bottom: 1rem
  #order-list-dashboard-desktop
    background-color: #FFFFFF
    padding: 10px
    border-radius: 8px
    border: 1px solid #ECEDED
    @media (max-width: 1200px)
      display: none !important
    table thead th
      padding: 12px
      position: sticky
      top: 0
      z-index: 1
      background: #EAEAEB
      padding: 12px
    table thead th:first-child
      position: sticky
      left: 0
      z-index: 2
      max-width: 120px
      min-width: 120px
    table thead th:nth-child(2)
      position: sticky
      left: 120px
      z-index: 2
    table tbody th
      position: sticky
      left: 0
      background: white
      z-index: 1
    table tbody tr td:first-child
      position: sticky
      left: 0
      z-index: 1
      background-color: white
      max-width: 120px
      width: 120px
    table tbody tr td:nth-child(2)
      position: sticky
      left: 120px
      z-index: 1
      background-color: white
      max-width: 200px
      width: 120px
    table thead tr th:last-child
      border-radius: 0px 8px 8px 0px
    table thead tr th:first-child
      border-radius: 8px 0px 0px 8px
    table thead tr th:nth-child(2), table tbody tr td:nth-child(2)
      border-right: 1px solid #3333331A
      box-shadow: 1px 0px 2px 0px #DBDBDB99
    table tbody tr td:nth-child(3)
      padding-left: 20px
    .table-container table thead tr
      .order-id, .status
        width: 150px
      .delivery-type, .delivery-date, .outlet, .customer, .date
        width: 150px
      .total, .pickup-time, .delivery-time
        width: 120px
      .total
        padding-left: 12px
      .delivery-address
        width: 200px
    a
      color: black
      text-decoration: none
    tr.draft
      a
        color: #95989D
    table tbody tr:nth-child(even)
      background-color: #F4F5F5
      td
        background-color: #F4F5F5
    table tbody tr td, table tbody th td
      padding: 12px
    .table-container
      overflow-x: scroll
      overflow-y: hidden
      width: 100%
      height: auto
      white-space: nowrap
      .draft
        color: #95989D

  @include keyframes(wave-lines)
    0%
      background-position: -468px 0
    100%
      background-position: 468px 0

  @include keyframes(wave-squares)
    0%
      background-position: -468px 0
    100%
      background-position: 468px 0

  @mixin wave-squares
    @include animate(wave-squares, 2s, infinite, ease-out)

  @mixin wave-lines
    @include animate(wave-lines, 2s, infinite, ease-out)

  .skeleton
    width: 100%
    background: #fff
    display: flex
    justify-content: center
    align-items: center

    .square
      @include wave-squares

      height: 80px
      border-radius: 5px
      background: rgba(130, 130, 130, 0.2)
      background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)))
      background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%)
      background-size: 800px 100px

    .line
      @include wave-lines

      height: 12px
      margin-bottom: 6px
      border-radius: 2px
      background: rgba(130, 130, 130, 0.2)
      background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)))
      background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%)
      background-size: 800px 100px

    .line:last-child
      margin-bottom: 0

    .text
      color: $grey-chateau
      font-size: 0.9em

  .skeleton--transparent
    background: transparent

  .skeleton-inner
    flex: 1

  .outlet-selector-selection
    height: 90px
    padding: 10px
    justify-content: space-between
    align-items: center
    cursor: pointer
    .outlet-selector-selection-group-text
      flex-direction: column
      padding: 10px 16px 10px 16px
  .outlet-selector-selection.mobile
    border: 1px solid #ECEDED
    border-radius: 8px
    margin-top: 5px
  .outlet-selector-selection.--selected
    background: #F4F5F5
  .outlet-selector-mobile-text-header
    margin-left: 5rem
    display: flex
    flex-direction: column
    align-items: center
  .vendor-selected-text
    flex-direction: column
    padding: 10px 16px 10px 16px
  .outlet-selector-mobile-footer-button
    padding: 20px
    margin-top: 1rem
    display: flex
    justify-content: space-between
    position: sticky
    bottom: 0
    z-index: 999
    border-top: 1px solid #F4F5F5
  .outlet-selector-desktop
    .outlet-selector-desktop-header
      align-items: center
      justify-content: space-between
      padding: 20px
      border-bottom: 1px solid #EAEAEB
  .outlet-selector-selection:hover
    background: #F4F5F5
  .flatpickr-input
    border: 1px solid #ECEDED
    height: 42px
    border-radius: 4px
    padding: 10px
    width: 250px

  .flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay
    background: $v2-primary-color !important
    border-color: $v2-primary-color !important
  .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange
    background: #F4FFD4 !important
    border-color: #F4FFD4 !important
  .flatpickr-day.inRange
    background: #F4FFD4 !important
    border-color: #F4FFD4 !important
    border: 1px solid #F4FFD4
    box-shadow: none !important
  .flatpickr-day.today
    border: 1px solid #141415 !important
    background: white !important
    color: black !important
  .flatpickr-calendar.arrowTop:before
    border: none
  .flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after
    bottom: initial
  .dayContainer
    padding-left: 22px !important
    padding-right: 22px !important
  .flatpickr-weekdays .flatpickr-weekdaycontainer
    padding-left: 22px !important
    padding-right: 22px !important
  .flatpickr-innerContainer
    border-bottom: none
  .flatpickr-calendar:before, .flatpickr-calendar:after
    display: none
  .active-tab-value
    padding: 0px 8px 0px 8px
    border: 1px
    color: white
    border-radius: 20px
    background-color: #7D9E1D
    height: 26px
    min-width: 26px
    display: inline-flex
    justify-content: center
    align-items: center

  .other-tab-value
    padding: 0px 8px 0px 8px
    border: 1px
    color: black
    border-radius: 20px
    background-color: white
  .active-tab
    width: 100%
    justify-content: center
    align-items: center
    padding: 8px 20px 8px 20px
    border-radius: 8px
    background-color: #FFFFFF
    color: #7D9E1D
    border: 1px solid rgba(234, 234, 235, 1)
  .tab-text
    margin-right: 8px
  .other-tab
    width: 100%
    justify-content: center
    align-items: center
    padding: 10px
  .outlets-list-container
    overflow: auto
    height: 75vh
    -ms-overflow-style: none
    scrollbar-width: none
  .outlets-list-container-mobile
    overflow: auto
    height: 90vh
    -ms-overflow-style: none
    scrollbar-width: none
  .outlets-list-container-mobile.outlets-list-container-mobile-outlets
    height: 80vh
  .outlets-list-container::-webkit-scrollbar, .outlets-list-container-mobile::-webkit-scrollbar
    display: none
