.order-items-header
  align-items: center
  display: flex
  justify-content: space-between
  margin: 10px 0 10px 15px

.order-items-header__title
  flex: 2

  h6
    font-size: 18px !important
    font-weight: 500 !important
    margin: 0

.order-items-header__selection
  align-items: center
  display: flex
  flex: 1
  justify-content: space-between

  label
    margin: 0 10px 0 0
