#orders-controller, #draft-orders-controller
  &.index-action
    .table-responsive
      min-height: 640px !important

    .table-responsive table
      table-layout: fixed
      width: 100%

  &.show-action
    .col-md-12.main-content
      padding-bottom: 0

      #secondary-nav .navbar
        margin-bottom: 0
        z-index: 999

    .display-title
      border-bottom: 1px solid #EAEAEB
      font-size: 15px
      font-weight: 500
      padding-bottom: 12px

    .c-input.c-checkbox.custom-control.custom-checkbox
      color: #95989D

    .loading
      margin: 0 auto
      text-align: center
      margin-top: 200px

      > div
        display: table-cell
        vertical-align: middle
        height: 450px

    .group-actions-form
      padding: 20px 0 !important

      &.pull-right
        padding: 0 !important

    .text-variation
      display: inline-block !important
      color: #95989D
      font-size: 11px

    /* Tables */
    .table
      margin-bottom: 30px

      thead th
        border-bottom: 1px solid #F4F5F5
        border-top: none

      tr.odd
        background-color: #FCFFF3

      tr.even
        background-color: #FFFFFF

      td, th
        padding: 10px 20px
        font-size: 13px
        font-weight: 400
        border-top: 1px solid #F4F5F5
        color: #141415

      th
        color: #95989D
        text-transform: uppercase
        font-size: 12px
        padding: 5px 20px

      td
        &.no-borders
          padding-top: 0
          padding-bottom: 0
          border-top: 0

        &.bottom-border-dotted
          border: none
          border-bottom: 1px dotted $nobel

        &.strong
          font-weight: bold

        &.labels-block
          color: $purple

          ul
            padding: 0 1rem

      &.table-sm-padding
        td
          padding: 8px 0
          line-height: 1.2

      .section-title
        color: $grey-chateau
        font-size: 12px
        text-transform: uppercase
        padding: 20px 0 5px !important

      .sub-total
        color: $gun-powder
        font-size: 15px
        font-weight: bold

      .grand-total
        color: $gun-powder
        font-size: 17px
        font-weight: bold

      .form-control, .text-area, .custom-select, .table-group-actions .btn
        height: 25px
        font-size: 13px

      .table-group-actions
        .btn
          line-height: 25px
          padding: 0 8px

        .nav-link:first-child.btn-transparent-danger
          margin-right: 10px

    .sidebar, .main-content
      float: none

    .order-basket-alert
      text-align: center
      font-size: 13px
      padding: 0.4rem

  aside
    p
      margin-bottom: 0

    .customer-info
      display: table-cell
      padding: 20px 30px

      .alert
        font-size: 13px

        h6
          font-size: 14px

        ul
          margin-bottom: 0
          padding-left: 30px

      .media
        margin: 0

        span
          font-size: 17px

      .custom-select
        width: 100%

      .btn
        padding-left: 15px
        padding-right: 15px

      .modal
        .btn-send
          width: 100%
          margin-top: 15px

        .form-control, .custom-select, .btn
          height: 40px
          line-height: 1
          font-size: 15px

      .form-control, .custom-select, .btn
        height: 30px
        line-height: 30px
        font-size: 13px

    .border-0 .form-control
      border-radius: 0 !important

    .group-block
      padding-bottom: 15px
      border-bottom: 1px solid #E6E9DD
      margin-bottom: 15px

    .group-block-email .btn
      float: left
      width: 48%

      &:first-child
        margin-right: 2%

    .notes
      margin-bottom: 10px

      p
        font-size: 13px
        line-height: 16px

      small
        color: #95989D
        font-size: 12px

  .sidebar-heading
    font-size: 12px
    color: #95989D
    line-height: 18px
    text-transform: uppercase
    margin-bottom: 10px

  .table.table-basket
    margin-top: 10px

    &.cafe-ordering
      th:first-child,
      td:first-child
        padding-left: 16px

    th
      font-size: 11px !important

    tr.--highlight
      background-color: $lavendar-blush
      box-shadow: 0px 1px 0px $gainsboro, -4px 0px 0px #bc2c9c

    th, td
      padding-left: 0

      &:last-child
        padding-right: 0

    tr
      &.subtotal-details
        td
          border-top: 1px solid #F4F5F5 !important
          padding-top: 16px !important

      &.total-details
        td
          border-top: none
          height: 35px
          line-height: 23px
          padding-top: 5px
          padding-bottom: 5px
          vertical-align: middle

          &:nth-child(3)
            padding-right: 0

            .btn
              height: 23px
              line-height: 23px
              font-size: 13px
              padding: 0 8px

          &.dotted
            border-top: 1px dotted #7D9E1D

            input
              padding: 0 5px
              width: 80px

    tr.subtotal .form-control
      padding: 0 !important
      width: 60px !important
      font-size: 13px
      color: #7D9E1D
      height: 18px !important
      background: transparent
      border: none
      border-bottom: 1px dotted #7D9E1D
      border-radius: 0
      box-shadow: none

    tr.total td
      padding-top: 5px
      font-size: 17px

    .basket-addon
      padding-left: 10px

  .total-quantity
    font-size: 17px

  .total-quantity__label
    margin-right: 5px

  .table-search
    .btn, .custom-select, .custom-select, .form-control
      padding: 0 10px !important
      height: 30px !important
      line-height: 30px !important
      font-size: 13px !important
      box-shadow: none !important

    .input-daterange .form-control
      width: 50%

  .special-instruction
    textarea
      min-height: 60px

    label, textarea, .btn
      font-size: 13px !important

    .btn
      padding-left: 15px
      padding-right: 15px
      height: 30px
      line-height: 30px

  /* Vendor Categories */
  .menu-categories
    .btn
      padding-right: .9375rem
      padding-left: .9375rem
      height: 50px
      line-height: 50px
      color: #141415

    .btn, .dropdown-item
      font-size: 14px

    .mobile-cart
      background: #7D9E1D
      width: auto
      display: inline-block
      font-size: 13px
      color: #fff
      padding-right: .9375rem
      padding-left: .9375rem
      float: right
      height: 50px
      line-height: 50px

      img
        margin-right: 8px

  .menu-categories-pill
    .nav-link
      font-size: 14px
      color: #95989D

    .nav-item
      margin-bottom: 5px

    .active .nav-link, .nav-link:hover
      color: #7D9E1D

  /* Menu */
  .menu
    textarea
      height: 90px

      &.form-control
        height: 72px

    .media-body-content
      padding: 0

    .row
      margin-bottom: 13px

    .form-control
      height: 36px
      line-height: 36px

    .display-6 em
      font-family: 'geomanistlight_italic', sans-serif
      font-weight: normal
      color: #95989D

    .checkbox, .radio
      margin-bottom: 6px

      span
        display: block
        padding: 2px 0 0 5px

    .checkbox label, .checkbox-inline, .radio label, .radio-inline
      font-size: 13px

    .text-variation
      display: inline-block !important
      color: #95989D

    .form-inline
      display: block
      clear: both
      margin-bottom: 10px

      .form-control
        width: 75px
        height: 30px
        width: 60px
        padding-left: 10px
        line-height: 30px
        margin-right: 7px
        margin-bottom: 0
        float: left

      .custom-select
        padding: 0 30px 0 10px
        line-height: 30px
        margin-right: 5px
        float: left
        display: inline-block

      label
        font-size: 13px
        display: inline-block
        float: left
        margin: 5px 0 0 0
        width: 80%

    .media
      padding: 20px 0 14px
      margin: 0
      border-bottom: 1px solid #EAEAEB

      &:last-child
        border-bottom: none
        border-radius: 0 0 3px 3px

      .media-body-content
        float: left
        width: 100%

      .media-object
        -webkit-border-radius: 3px
        -moz-border-radius: 3px
        border-radius: 3px

    .media-left
      padding: 0 20px 20px
      display: block

    .media-object
      width: 100%
      height: auto

    .media-body-content
      padding: 0 20px

    .media-body
      .media-desc, .see-more
        margin: 0
        font-size: 13px
        line-height: 20px

      .media-desc
        margin-top: 3px
        color: #515154

    .media-action
      width: 20%
      float: right
      position: relative

      .btn
        position: absolute
        top: 0
        right: 0

      .btn-cart
        width: 30px !important
        height: 30px
        background: #7D9E1D url('#{$images-url-path}/icon-cart.png') no-repeat 50% !important
        background-size: 12px 14px !important
        padding: 0

        &:hover
          background: #9ABC37 url('#{$images-url-path}/icon-cart.png') no-repeat 50% !important
          background-size: 12px 14px !important

    .media-heading
      float: left
      width: 80%
      font-size: 15px
      line-height: 21px
      color: #141415
      margin-bottom: 5px

      .menu-price
        font-size: 18px
        font-family: "proxima-nova-soft",sans-serif
        font-weight: 500
        padding-left: 10px

        span
          font-size: 13px
          color: #95989D

    .media-highlight
      padding-top: 9px !important
      background: #FCFFF3 !important

      .highlight-text
        text-transform: uppercase
        font-size: 12px
        color: #7D9E1D
        margin: 0
        padding: 0 20px 9px

        img
          margin: -2px 5px 0 0

    .media-meta
      .menu-price
        list-style: none
        padding: 0
        width: 100%
        margin: 0 10px 0 0
        width: auto
        float: left

        li
          margin: 0
          line-height: 18px
          text-align: left
          font-size: 15px
          color: #141415

          span
            font-size: 13px
            margin-left: 3px

      .menu-qty
        list-style: none
        font-size: 13px
        float: left
        padding: 0
        margin: 1px 10px 0 0

      .menu-label-tags
        list-style: none
        font-size: 13px
        float: left
        padding: 0
        margin-bottom: 0

        li
          float: left
          margin-left: 8px

          &:first-child
            margin-left: 0

          a, a img
            display: inline-block
            width: auto
            height: 16px

    .media-footer
      padding-top: 14px

      p
        margin-bottom: 0

    .menu-vendor
      margin-top: 10px

      img
        border-radius: 3px
        float: left
        margin-right: 9px

      h6
        font-size: 13px

    .ribbon
      border: 1px solid #EAEAEB
      color: #7D9E1D
      font-size: 13px
      display: inline-block
      padding: 0 10px
      border-radius: 3px
      height: 20px
      line-height: 17px
      margin-left: 7px

    .display-7 a
      font-size: 13px

    .informational-text
      h6
        font-family: 'geomanistbook', sans-serif
        font-size: 13px
        margin: 0

      .text-muted
        margin: 0

    .menu-category
      background: #FFFFFF
      margin-bottom: 15px

  .menu-headline-wrapper
    padding: 0 20px 16px
    border-bottom: 1px solid #EAEAEB

    h3
      font-family: 'geomanistbook', sans-serif
      font-size: 18px
      margin-bottom: 3px

    p
      margin: 0
      font-size: 13px

  .menu-headline-banner
    height: 90px
    overflow: hidden

    img
      width: 100%
      height: auto

  .btn-special-instruction
    font-size: 15px
    font-family: "proxima-nova-soft",sans-serif
    color: #7D9E1D
    background: url('#{$images-url-path}/special-instruction-icon.svg') 0 50% no-repeat
    background-size: 16px 16px
    padding-left: 26px

  #special-instruction
    margin: 15px 0 10px 0

    textarea
      box-shadow: none
      font-weight: normal !important
      font-size: 15px !important
      color: #141415 !important

  /* Modal */
  .modal
    .btn-cart
      height: 36px
      line-height: 36px
      text-indent: 21px
      padding: 0 15px
      background: #7D9E1D url('#{$images-url-path}/icon-cart.png') no-repeat 15px 50% !important
      background-size: 12px 14px !important
      font-size: 13px

      &:hover
        background: #9ABC37 url('#{$images-url-path}/icon-cart.png') no-repeat 15px 50% !important
        background-size: 12px 14px !important

    .media
      padding: 0
      margin: 0

      .media-body-content
        float: left
        width: 100%
        padding-left: 0

    .row
      margin-bottom: 10px

    .form-inline .form-control
      width: 75px
      margin-right: 7px

  .modal-body
    padding: 15px !important

    .container
      border: none !important
      box-shadow: none !important
      border-radius: 0
      min-height: auto!important

  /* History */
  .history-list
    padding: 30px 0 0 0
    margin: 20px 0 0 0
    list-style: none
    color: #141415
    font-size: 15px
    border-top: 1px solid #EAEAEB

    .history
      border-radius: 3px
      border: 1px solid #EAEAEB
      margin-bottom: 10px
      background: #fff
      padding: 10px 15px

      .time-stamp
        font-size: 13px
        color: #95989D

      &.history-note
        border: 1px solid #E9EAD1
        background: #FEFFE6

  @media (min-width:48em)
    .menu
      .media-left
        display: table-cell
        padding: 0 0 0 20px

      .media-object
        width: 200px
        height: 113px

  @media (max-width: 33.9em)
    .menu-categories
      .navbar
        padding: 0 20px
        height: auto !important

      .nav-item
        &:last-child a
          border: none

        a
          border: none
          padding: 0

      .navbar-nav
        margin-top: 0
        width: 114%
        margin-left: -7%
        border: none
        box-shadow: none

        .nav-item
          color: #141415
          height: 40px
          line-height: 40px
          width: 100%
          border: none

    .vendor-banner .media-left, .menu .media-left
      display: none

    .menu .media-body-content
      width: 70%
      padding-right: 0

    .media-action
      width: 30%

  @media (min-width: 48em)
    .modal-lg
      width: 760px

    .main-content
      .sidebar
        display: table-cell
        width: 30%

      .main-content
        display: table-cell
        width: 70%

      .main-content-no-sidebar
        width: 100%

  @media (max-width: 74.9em)
    .vendor-info
      padding: 20px 0 0

      ul li
        margin-bottom: 20px
