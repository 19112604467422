/* Header & Navigation */
#main-nav
  .navbar-brand
    height: 40px
    padding-top: 1rem
    text-align: left
    display: flex

  .navbar
    border-radius: 0
    padding: 0 30px
    height: 70px
    transition: background .5s ease-in-out,padding .5s ease-in-out
    padding: 0 15px

    .nav-item
      padding: 0
      height: 70px
      line-height: 70px

      a
        color: #fff
        border-radius: 0
        margin: 0
        font-size: 15px
        padding: 0
        width: 100%
        display: block

        img
          height: 15px
          margin-right: 5px
          margin-top: -3px

      .btn-group
        .btn-naked
          padding: 0
          margin: -5px 0 0

        img
          margin-right: 5px
          margin-top: -3px

    .navbar-item--white
      a
        color: $white

        &:hover
          color: $white

      &.navbar-item--active
        a
          color: $barberry

    .i-nav
      color: #848689
      margin-right: 5px

    .dropdown-menu
      background-color: hsla(0,0%,100%,.98)
      color: #141415!important
      padding: 20px
      font-size: 15px
      width: 100%
      border-radius: 0 0 3px 3px
      margin: 16px 0 0
      border-top: 1px solid hsla(0,0%,100%,.15)

      li
        margin-bottom: 15px
        line-height: normal

        &:last-child
          margin-bottom: 0

        a
          color: #141415 !important

          &:hover
            background-color: transparent
            color: #7D9E1D !important

      .dropdown-header
        font-size: 15px
        padding: 0

      .drowpdown-header-black
        font-size: 15px
        padding: 0
        color: #141415

    .dropdown-toggle
      &:after
        border-top-color: #95989D

    .pull-right .dropdown-menu
      background-color: hsla(0,0%,100%,.98)
      color: #141415!important
      padding: 20px
      font-size: 15px
      min-width: 150px
      width: auto
      border-radius: 0 0 3px 3px
      margin: 0
      border-top: 1px solid hsla(0,0%,100%,.15)

    .multi-column-dropdown
      list-style: none
      margin: 0
      padding: 0

    .btn-login
      height: 36px
      line-height: 36px
      border: 1px solid #54414E
      background: transparent
      margin-top: -5px

  .navbar-dark .navbar-nav .nav-link
    color: #fff
    padding: 0

  .navbar-toggler
    width: 100%
    text-align: right

  .navbar-nav.pull-left .nav-item
    border-left: 1px solid hsla(0,0%,100%,.15)
    padding: 0 20px

    &:last-child
      border-right: 1px solid hsla(0,0%,100%,.15)

  .top-nav-collapse
    background: rgba(30,3,19,0.80)!important
    background-image: linear-gradient(-180deg, rgba(30,3,19,0.30) 0%, rgba(30,3,19,0.00) 73%)

  .btn-group-map
    margin-left: 20px
    margin-right: 20px

  .navbar-divider
    margin: 0

.navbar-nav .nav-item+.nav-item,
.navbar-nav .nav-link+.nav-link
  margin: 0

.nav-item-phone img
  margin-right: 5px
  margin-top: -3px

@media (max-width: 33.9em)
  #main-nav
    .dropdown
      position: static

    .navbar
      padding: 0 15px

    .navbar .pull-right .dropdown-menu
      width: 100%
      margin: 0
      border-radius: 0
      border: none
      border-bottom: 1px solid #eaeaeb
      border-top: 1px solid #eaeaeb
      box-shadow: 0px 8px 16px rgba(20,20,21,0.08)

    .navbar-brand
      width: 92px

    .navbar-toggler
      margin-top: 16px

    .navbar-nav
      margin-right: -20px

    .navbar-nav.pull-right .nav-item .btn-group
      width: 100%

    .navbar-nav.pull-right .nav-item:last-child .btn-group .btn
      width: 100%
      text-align: right
      padding-right: 8px

    .navbar-nav .nav-item
      color: #141415!important
      height: 50px
      line-height: 50px
      width: 100%

    .navbar-nav .nav-item a
      color: #141415!important

    .navbar-nav.pull-left .nav-item .btn-group .btn,
    .navbar-nav.pull-left .nav-item .btn-group
      width: 100%
      text-align: left

    .navbar-nav.pull-right .nav-item.nav-item-phone
      padding-left: 20px

    .language-label
      display: none

.navbar-nav.pull-right .nav-item:last-child
  padding-left: 20px

/* Secondar Nav */
#secondary-nav
  .navbar-divider
    margin: 0

  .nav-counter
    font-size: 13px
    border: 1px solid #EAEAEB
    border-radius: 5px
    color: #141415
    display: inline-block
    padding: 2px 5px 0
    line-height: 13px
    margin: 0 0 0 5px
    background: #FFFFFF

  .navbar
    border-radius: 0
    padding: 0
    height: 50px
    border-top: 1px solid #EAEAEB
    border-bottom: 1px solid #EAEAEB
    margin-bottom: 30px

    .nav-item
      height: 50px
      font-weight: 500
      line-height: 50px
      padding: 0
      border-left: 1px solid #eaeaeb
      margin-left: 0
      background-color: #ffffff
      border-bottom: 1px solid #eaeaeb

      &:first-child
        border-left: none

      &:last-child
        border-right: 1px solid #EAEAEB

      &.active a
        color: #7D9E1D
        box-shadow: inset 0 -2px 0 #7D9E1D

      a
        font-size: 15px
        color: #141415
        margin: 0
        padding: 0 25px
        cursor: pointer

  .dropdown-title, .navbar-toggler
    display: none

.navbar-nav .nav-item+.nav-item,
.navbar-nav .nav-link+.nav-link
  margin: 0

@media (min-width: 48em)
  #secondary-nav .navbar-collapse
    display: flex

@media (max-width: 48em)
  #secondary-nav
    .navbar
      padding: 0
      z-index: 999

    .navbar .nav-item
      width: 100%
      border-bottom: none

    .navbar .nav-item.active a
      color: #7d9e1d
      box-shadow: none

    .navbar-nav
      display: flex
      flex-direction: column
      box-shadow: 0px 8px 16px rgba(20,20,21,0.08),0px 1px 2px rgba(20,20,21,0.16)

    .navbar-collapse
      flex-basis: 100%
      flex-grow: 1
      align-items: center

    .dropdown-title
      display: unset
      font-weight: 500
      margin-top: 15px
      padding-left: 15px

    .navbar-toggler
      display: unset
      background-image: none
      width: 100%
      height: 50px
      padding: 0 0 0 15px
      border-radius: 0
      color: #7d9e1d
      box-shadow: inset 0 -2px 0 #7d9e1d
      font-size: 15px
      text-align: left

/* Group Action */

.homepage-settings-line
  margin-top: 55px

.group-actions, .group-actions-form
  padding: 20px 30px

  .heading-title h6
    margin-top: 11px
    font-family: "proxima-nova-soft",sans-serif
    font-weight: 500
    font-size: 17px

  .btn
    padding: 0 15px

  .nav-inline .nav-link+.nav-link
    margin-left: 0

  .nav-link
    padding: 0 20px !important
    border-left: 1px solid #EAEAEB
    height: 40px
    line-height: 40px

    &:first-child
      padding-top: 20px
      border-left: none

    &.btn
      margin-left: 5px !important

    &.p-r-0
      padding-right: 0 !important

  .fa
    margin-right: 5px

.group-actions-header
  border-bottom: 1px solid #EAEAEB

/* Table Group Action */
.table-group-actions
  .btn
    padding: 0 20px
    height: 30px
    line-height: 30px

  .nav-inline .nav-link+.nav-link
    margin: 0

  .nav-link:first-child
    border-left: 0
    border-right: 1px solid #F4F5F5

    &.btn-transparent-danger
      margin-right: 20px
      padding: 0 15px

.table-search
  margin-bottom: 30px

/* Nav Pills */
.nav-pills
  padding: 0 30px !important

/* Layout */
.content-wrapper
  padding-left: 15px
  padding-right: 15px

  .container.wrapper
    background: #fff!important
    border: 1px solid #eaeaeb
    box-shadow: 0px 4px 8px rgba(20, 20, 21, 0.04)
    border-radius: 3px 3px 0 0
    padding: 0
    margin-bottom: 8px

  .row.no-margin
    margin-left: 0
    margin-right: 0

  .main-content
    padding-right: 0
    padding-left: 0
    padding-bottom: 30px

.sidebar
  padding-right: 0 !important
  padding-left: 0 !important
  background: #FCFFF3 !important
  border-right: 1px solid #E6E9DD !important
  height: 100% !important
  border-top-left-radius: 3px !important
  color: #141415 !important

.content-box
  padding: 0 15px

  .group-actions
    padding: 20px 0

  @media (min-width: 48em)
    padding: 0 30px

/* Panel */
.panel-default
  border-color: #D5D6D8

  > .panel-heading
    color: #141415
    border-color: #D5D6D8
    font-family: "proxima-nova-soft",sans-serif
    font-weight: 500
    font-size: 15px

.panel
  margin-bottom: 40px
  background-color: #fff
  border: 1px solid #D5D6D8
  border-radius: 3px

.panel-heading
  padding: 12px 20px
  border-bottom: 1px solid transparent
  border-top-left-radius: 3px
  border-top-right-radius: 3px

.panel-body
  padding: 20px


/* Vendor Graphic */
.vendor-graphic-container
  background: #FFFFFF
  border: 1px solid #D5D6D8
  border-radius: 3px
  padding: 10px
  margin-bottom: 15px

.partner-banner-graphic
  padding: 0

.vendor-graphic
  background: #FFFFFF url('#{$images-url-path}/vendor-graphic-icon.svg') no-repeat
  background-size: 58px 46px
  background-position: 50% 30%
  border: 1px dashed #D5D6D8
  border-radius: 3px
  text-align: center
  padding: 80px 20px 20px 20px

  small
    font-size: 12px
    color: #95989D

  &.partner-banner-graphic
    padding: 0


.img-circle
  background: #fff
  padding: 3px
  border: 1px solid #E5EADD

.required label:after
  color: #e32
  content: " *"

.file-upload-container
  width: 100%
  height: 150px
  border: 1px solid #D5D6D8
  border-radius: 3px
  background: #fff
  cursor: pointer
  padding: 4px
  position: relative
  overflow: hidden

  .help-text
    width: 100%
    height: 100%
    display: table
    position: absolute
    font-size: 12px

    > div
      display: table-cell
      padding: 10px
      text-align: center
      vertical-align: middle

  .preview-container
    background: #F4F5F5
    position: relative
    height: 100%

  .image-preview,
  .upload-preview
    width: 100%
    height: 100%
    background-size: cover
    background-position: 50%
    background-repeat: no-repeat
    position: absolute

  .image-preview
    z-index: 1

  .upload-preview
    z-index: 2


.preview-container
  position: relative

  .card
    border: 1px solid #D5D6D8

  .card-img-top
    background-size: cover
    height: 150px
    position: relative

  .card-block
    border-top: 1px solid #EAEAEB
    padding: 10px

  .btn-delete
    position: absolute
    top: -11px
    right: 0
    display: inline-block
    width: 24px
    height: 24px
    background: url('#{$images-url-path}/delete-photo.png') no-repeat
    background-size: 24px 24px
    border: none
    cursor: pointer
    text-indent: -9999px

  .help-text
    width: 100%
    height: 100%
    display: table
    position: absolute
    font-size: 12px

    > div
      display: table-cell
      padding: 10px
      text-align: center
      vertical-align: middle

.fa.fa-check-circle-o
  color: #BBB !important

#calendar
  table
    td.text-muted
      display: table-cell !important

  .popover-title
    .text-center
      font-size: 16px
      font-weight: bold
      text-align: center !important

    .text-muted
      margin-top: -1px

.btn-search
  height: inherit !important
  line-height: inherit !important
  padding: 8px 15px !important

.fade.in
  opacity: 1

/* Popover */
.popover.top, .popover.bs-tether-element-attached-bottom
  margin-top: -10px

.popover.top::before, .popover.top::after, .popover.bs-tether-element-attached-bottom::before, .popover.bs-tether-element-attached-bottom::after
  left: 50%
  border-bottom-width: 0

.popover.top::before, .popover.bs-tether-element-attached-bottom::before
  bottom: -11px
  margin-left: -11px
  border-top-color: rgba(0, 0, 0, 0.25)

.popover.top::after, .popover.bs-tether-element-attached-bottom::after
  bottom: -10px
  margin-left: -10px
  border-top-color: #fff

.popover.right, .popover.bs-tether-element-attached-left
  margin-left: 10px

.popover.right::before, .popover.right::after, .popover.bs-tether-element-attached-left::before, .popover.bs-tether-element-attached-left::after
  top: 50%
  border-left-width: 0

.popover.right::before, .popover.bs-tether-element-attached-left::before
  left: -11px
  margin-top: -11px
  border-right-color: rgba(0, 0, 0, 0.25)

.popover.right::after, .popover.bs-tether-element-attached-left::after
  left: -10px
  margin-top: -10px
  border-right-color: #fff

.popover.bottom, .popover.bs-tether-element-attached-top
  margin-top: 10px

.popover.bottom::before, .popover.bottom::after, .popover.bs-tether-element-attached-top::before, .popover.bs-tether-element-attached-top::after
  left: 50%
  border-top-width: 0

.popover.bottom::before, .popover.bs-tether-element-attached-top::before
  top: -11px
  margin-left: -11px
  border-bottom-color: rgba(0, 0, 0, 0.25)

.popover.bottom::after, .popover.bs-tether-element-attached-top::after
  top: -10px
  margin-left: -10px
  border-bottom-color: #f7f7f7

.popover.bottom .popover-title::before, .popover.bs-tether-element-attached-top .popover-title::before
  position: absolute
  top: 0
  left: 50%
  display: block
  width: 20px
  margin-left: -10px
  content: ""
  border-bottom: 1px solid #f7f7f7

.popover.left, .popover.bs-tether-element-attached-right
  margin-left: -10px

.popover.left::before, .popover.popover-left::after, .popover.bs-tether-element-attached-right::before, .popover.bs-tether-element-attached-right::after
  top: 50%
  border-right-width: 0

.popover.left::before, .popover.bs-tether-element-attached-right::before
  right: -11px
  margin-top: -11px
  border-left-color: rgba(0, 0, 0, 0.25)

.popover.left::after, .popover.bs-tether-element-attached-right::after
  right: -10px
  margin-top: -10px
  border-left-color: #fff

/* Star Rating

.star-ratings
  background: url("#{$images-url-path}/star-rating-sprite.png") repeat-x
  font-size: 0
  height: 20px
  line-height: 0
  overflow: hidden
  text-indent: -999em
  width: 108px
  margin: 0 0 4px 0

  &.star-sm-ratings
    background: url("#{$images-url-path}/star-sm-rating-sprite.png") repeat-x
    height: 10px
    width: 58px

.star-ratings-rating
  background: url("#{$images-url-path}/star-rating-sprite.png") repeat-x
  background-position: 0 100%
  float: left
  height: 20px
  display: block

  &.star-sm-ratings-rating
    background: url("#{$images-url-path}/star-sm-rating-sprite.png") repeat-x
    background-position: 0 100%
    height: 10px

label.custom-control
  min-height: 1.5rem

.text-violet
  color: #864898 !important

.m-50
  margin-top: 50px !important
  margin-bottom: 50px !important

.m-b-20
  margin-bottom: 20px !important

.m-l-5
  margin-left: 5px !important

.m-r-5
  margin-right: 5px !important

.m-r-20
  margin-right: 20px !important

.m-t-10
  margin-top: 10px !important

.m-t-20
  margin-top: 20px !important

.m-t-30
  margin-top: 30px !important

.p-b-0
  padding-bottom: 0 !important

.p-b-15
  padding-bottom: 15px !important

.p-l-30
  padding-left: 30px

.p-r-0
  padding-right: 0 !important

.p-t-10
  padding-top: 10px !important

.p-t-20
  padding-top: 20px !important

.width-50
  width: 50%

.width-100
  width: 100%

.fa-white
  color: $white

// From Bootstrap
//
// Remove this if we upgrade to newer version
.no-gutters
  margin-right: 0
  margin-left: 0

  > .col,
  > [class*="col-"]
    padding-right: 0
    padding-left: 0

.d-flex
  display: flex !important

.justify-content-center
  justify-content: center

.align-items-center
  align-items: center


$green: #7d9e1d
$gray-300: #dbdbdb
$primary: $green

.btn 
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08)

  &.btn-light
    border: 1px solid $gray-300
    background: white
    
    &:hover
      background: $gray-100
    
    &.active
      background: $primary !important
      color: white !important
      border-color: $primary !important
.btn-small
  padding: 0.25rem 0.5rem !important
  font-size: 0.875rem !important
  line-height: 1.5 !important
  border-radius: 0.2rem !important

.admin-table td
  padding: 15px 20px
  font-weight: 400
  color: #515154 !important


@media (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px)
  table.admin-table thead, table.admin-table tbody, table.admin-table th, table.admin-table td, table.admin-table tr
    display: block
    border: none !important
  table.admin-table td:not(:nth-last-child(2))
    padding-bottom: initial !important
  table.admin-table thead
    display: none
  table.admin-table tbody tr
    border-bottom: 1px solid #f4f5f5 !important
  table.admin-table tr td:last-child
    display: none
  .group-actions-responsive
    padding: 20px
    display: block !important
  .group-actions-desktop
    display: none !important
  .td-email
    display: flex !important
    justify-content: space-between
  .elipses-email
    display: block !important

.permission-header
  margin-top: 10px
  font-weight: 500
  font-size: 24px !important
  line-height: 110%
  color: #141415
