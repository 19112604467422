#caterers-controller, #vouchers-controller
  &.new-action, &.edit-action
    .nav-link > span
      margin-right: 5px

    .react-toggle
      top: 8px

.order-alert__children
  margin-top: 24px
  max-width: 720px
