.order-diff__title
  color: $mojo

.order-diff__badge
  background-color: rgba(195, 66, 63, 0.18)
  border-radius: 4px
  border: 1px solid rgba(0, 0, 0, 0.08)
  display: inline-block
  font-size: 12px
  margin-bottom: 16px
  margin-top: 24px
  padding: 4px 16px
  text-transform: uppercase

.order-diff__table-header
  background-color: $gray-200
  font-size: 12px
  padding: 6px 0 6px 16px
  text-transform: uppercase

.order-diff__table-content
  font-size: 14px
  padding: 6px 16px 6px 16px
  height: 100%

.order-diff__arrow
  background-color: $gray-100
  color: $mojo
  font-size: 1.5rem
  padding-right: 38px
  text-align: right
  height: 100%

.order-diff__table-content--with-bg
  background-color: $gray-100

/* Overrides */

.alert
  .order-diff__arrow,
  .order-diff__table-content--with-bg
    background-color: $wafer

  .order-diff__table-header
    background-color: $swiss-coffee

#orders-controller
  .modal
    .row
      margin-bottom: 0

