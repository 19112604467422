@font-face
  font-family: 'proxima-nova-soft'
  font-display: swap
  src: url('#{$fonts-url-path}/proxima-nova-soft-regular-webfont.eot')
  src: url('#{$fonts-url-path}/proxima-nova-soft-regular-webfont.eot?#iefix') format('embedded-opentype'), url('#{$fonts-url-path}/proxima-nova-soft-regular-webfont.woff') format('woff'), url('#{$fonts-url-path}/proxima-nova-soft-regular-webfont.ttf') format('truetype'), url('#{$fonts-url-path}/proxima-nova-soft-regular-webfont.svg') format('svg')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'proxima-nova-soft'
  font-display: swap
  src: url('#{$fonts-url-path}/proxima-nova-soft-semibold-webfont.woff')
  font-weight: 600
  font-style: normal

@font-face
  font-family: 'geomanistbook'
  src: url('#{$fonts-url-path}/geomanist-book-webfont.eot')
  src: url('#{$fonts-url-path}/geomanist-book-webfont.eot?#iefix') format('embedded-opentype'), url('#{$fonts-url-path}/geomanist-book-webfont.woff2') format('woff2'), url('#{$fonts-url-path}/geomanist-book-webfont.woff') format('woff'), url('#{$fonts-url-path}/geomanist-book-webfont.ttf') format('truetype'), url('#{$fonts-url-path}/geomanist-book-webfont.svg') format('svg')
  font-weight: normal
  font-style: normal


@font-face
  font-family: 'geomanist_regularregular'
  font-display: swap
  src: url("#{$fonts-url-path}/geomanist-regular-webfont.eot")
  src: url("#{$fonts-url-path}/geomanist-regular-webfont.eot?#iefix") format("embedded-opentype"), url("#{$fonts-url-path}/geomanist-regular-webfont.woff2") format("woff2"), url("#{$fonts-url-path}/geomanist-regular-webfont.woff") format("woff"), url("#{$fonts-url-path}/geomanist-regular-webfont.ttf") format("truetype"), url("#{$fonts-url-path}/geomanist-regular-webfont.svg") format("svg")
  font-weight: normal
  font-style: normal