#sessions-controller, #confirmations-controller, #passwords-controller
  &.new-action, &.create-action,&.edit-action
    .content-wrapper .container.wrapper
      background: none !important
      border: 0
      box-shadow: 0 0 0 0
      border-radius: 0

    .card-header
      box-shadow: inset 0 0 1px 0px rgba(0, 0, 0, 0.125)
      background: #FCFFF3
      border-bottom: 1px solid #e6e9dd
      padding: 17px 20px
