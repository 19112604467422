span.flatpickr-day, span.flatpickr-day.prevMonthDay, span.flatpickr-day.nextMonthDay
  border: none !important
  max-width: initial !important
.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange
  border-radius: 50px !important
.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange
  border-radius: 50px !important



.flatpickr-months .flatpickr-month
  background: white !important

.flatpickr-weekdays
  background: white !important
  margin-top: 20px
span.flatpickr-weekday
  background: white !important
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1))
  box-shadow: none !important

.flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path
  fill: black !important
.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg
  fill: black !important
.flatpickr-prev-month, .flatpickr-next-month
  margin-top: 10px
.flatpickr-current-month
  padding: 17.48px 0 0 0
.flatpickr-months
  height: 44px
  background: white
  border-radius: 8px
.flatpickr-months .flatpickr-month
  margin-top: 10px
.react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--today
  border: none
  background: #f2f5e8
